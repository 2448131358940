import { string, object, Decoder, DecoderType, array, boolean, either, constant, email } from "decoders";
import { SecretQuestionData, secretQuestionDataDecoder } from "Shared/SecretQuestionsForm/interfaces";
import { JsendResponse, JsendSuccess, jsendSuccessDecoder, jsendResponseDecoder } from "Utils/jsend";

const securityQuestionsDecoder = array(secretQuestionDataDecoder);

const profileDecoder = object({
  id: string,
  phone: string,
  email: email,
  address: object({
    country: string,
    state: string,
    city: string,
    region: string,
    reference: string,
    sector: string,
  }),
  securityQuestions: securityQuestionsDecoder,
});

export type Profile = DecoderType<typeof profileDecoder>;

export type ProfileResponse = JsendSuccess<Profile>;

export const profileResponseDecoder: Decoder<ProfileResponse> = jsendSuccessDecoder(profileDecoder);

export type ProfileTokenVerifyResponse = JsendSuccess<boolean>;

export const profileTokenVerifyResponseDecoder: Decoder<ProfileTokenVerifyResponse> = jsendSuccessDecoder(boolean);

const confirmCredentialsResponseFailDecoder = object({
  errorKey: either(constant("invalidSecretQuestions"), constant("invalidCredentials")),
  message: string,
});

type ConfirmCredentialsResponseFail = DecoderType<typeof confirmCredentialsResponseFailDecoder>;
export interface ProfileTokenRequest {
  password: string;
  secretQuestions: SecretQuestionData[];
}

export type ProfileTokenResponse = JsendResponse<string, ConfirmCredentialsResponseFail>;

export const profileTokenResponseDecoder: Decoder<ProfileTokenResponse> = jsendResponseDecoder(
  string,
  confirmCredentialsResponseFailDecoder
);

export interface ChangeEmailRequest {
  token: string;
  code: string;
  email: string;
}

export type ChangeProfileResponse = JsendResponse<string, string>;

export const changeEmailResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangePhoneRequest {
  token: string;
  code: string;
  phone: string;
}

export const changePhoneResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangePasswordRequest {
  token: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const changePasswordResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangeQuestionsRequest {
  token: string;
  newSecretQuestions: DecoderType<typeof securityQuestionsDecoder>;
}

export const changeQuestionsResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);

export interface ChangeAddressRequest {
  address: {
    country: string;
    state: string;
    city: string;
    region: string;
    reference: string;
    sector: string;
  };
}

export const changeAddressResponseDecoder: Decoder<ChangeProfileResponse> = jsendResponseDecoder(string, string);
