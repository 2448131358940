import { constant, Decoder, either, object } from "decoders";

export type JsendResponse<S, F> = JsendSuccess<S> | JsendFail<F>;

export interface JsendSuccess<T> {
  status: "success";
  data: T;
}

export interface JsendFail<T> {
  status: "fail";
  data: T;
}

export function jsendSuccessDecoder<S>(dataDecoder: Decoder<S>) {
  return object({ status: constant("success"), data: dataDecoder });
}

export function jsendFailDecoder<S>(dataDecoder: Decoder<S>) {
  return object({ status: constant("fail"), data: dataDecoder });
}

export function jsendResponseDecoder<S, F>(successDataDecoder: Decoder<S>, failDataDecoder: Decoder<F>) {
  return either(jsendSuccessDecoder(successDataDecoder), jsendFailDecoder(failDataDecoder)) as unknown as Decoder<
    JsendResponse<S, F>
  >;
}
