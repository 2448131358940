import { styled, Tabs, Tab, Box, useTheme, useMediaQuery, Stack, Typography, IconButton } from "@mui/material";
import { Logout } from "@mui/icons-material";
import NavBar from "Layout/NavBar/NavBar";
import { useSingleEffect } from "Hooks/useSingleEffect";
import { UserData } from "Pages/Login/interfaces";
import { Fragment, PropsWithChildren, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentUser, logout } from "Services/api/auth/auth";
import { Backdrop, BackdropState } from "Shared/Backdrop/Backdrop";
import { useSnackbar } from "notistack";

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#fff",
  },
  "& .MuiTabs-indicatorSpan": {
    backgroundColor: "#fff",
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#fff",
  fontWeight: "bold",
  "&.Mui-selected": {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    marginTop: "10px",
    borderRadius: "15px 15px 0 0",
    fontWeight: "bold",
  },
}));

export default function Dashboard({ children }: PropsWithChildren): JSX.Element {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | null>(null);
  const [backdropState, setBackdropState] = useState<BackdropState>({
    open: true,
    msg: "Cargando ...",
  });
  const { pathname } = useLocation();
  const tab = pathname.includes("/socio") ? 1 : 0;

  useSingleEffect(() => void onLoad());

  return (
    <Fragment>
      {user && (
        <NavBar rightMenu={<RightMenu name={`${user.first_name} ${user.last_name}`} />}>
          <Box sx={{ pt: 8, px: "48px", backgroundColor: "#547725" }}>
            <StyledTabs value={tab} aria-label="menu de navegación" centered variant="fullWidth">
              <StyledTab label="Mis productos" onClick={() => navigate("/productos")} />
              <StyledTab label="Información del socio" onClick={() => navigate("/socio")} />
            </StyledTabs>
          </Box>
          <Box
            sx={{
              pt: "15px",
              px: "48px",
            }}
          >
            {children}
          </Box>
        </NavBar>
      )}
      <Backdrop {...backdropState} />
    </Fragment>
  );

  async function onLoad() {
    const goToLogin = () => navigate("/acceso");
    const token = localStorage.getItem("token");
    if (!token) {
      goToLogin();
      return;
    }

    try {
      const { status, data } = await getCurrentUser();
      if (status === "success") {
        setUser(data);
      } else {
        goToLogin();
      }
    } catch (error) {
      goToLogin();
      console.error(error);
    } finally {
      setBackdropState({
        open: false,
      });
    }
  }
}

function RightMenu({ name }: { name: string }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      {!isSmall && <Typography>Bienvenido/a {name}</Typography>}

      <IconButton
        className="logout-button"
        sx={{ backgroundColor: "#fff" }}
        onClick={() => void onLogout()}
        aria-label="cerrar sesión"
        data-testid="logout"
      >
        <Logout htmlColor={theme.palette.primary.main} />
      </IconButton>
    </Stack>
  );

  async function onLogout() {
    try {
      await logout();
      localStorage.removeItem("token");
      navigate("../acceso");
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error al intentar cerrar sesión", {
        variant: "error",
      });
      console.error(error);
    }
  }
}
