import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS_TOKEN } from "../config";
import {
  GeneralBalanceResponse,
  generalBalanceResponseDecoder,
  savingAccountDetailsResponseDecoder,
  SavingAccountDetailsResponse,
  certificateDetailsResponseDecoder,
  CertificateDetailsResponse,
  CertificateTransactionsResponse,
  certificateTransactionsResponseDecoder,
  SavingAccountTransactionsResponse,
  savingAccountTransactionsResponseDecoder,
  LoanDetailsResponse,
  loanDetailsResponseDecoder,
} from "./interfaces";

export async function getGeneralBalance(): Promise<GeneralBalanceResponse> {
  const response = await enhancedFetch(`${API_URL}/finance/general-balance/`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return generalBalanceResponseDecoder.verify(await response.json());
}

export async function getSavingsAccountDetails(
  accountNumber: string | number,
  from = "",
  to = ""
): Promise<SavingAccountDetailsResponse> {
  const searchParams = new URLSearchParams({ from, to }).toString();
  const response = await enhancedFetch(`${API_URL}/finance/saving-account/${accountNumber}/?${searchParams}`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return savingAccountDetailsResponseDecoder.verify(await response.json());
}

export async function getSavingsAccountTransactions(
  accountNumber: string | number,
  from = "",
  to = ""
): Promise<SavingAccountTransactionsResponse> {
  const searchParams = new URLSearchParams({ from, to }).toString();
  const response = await enhancedFetch(
    `${API_URL}/finance/saving-account-transactions/${accountNumber}/?${searchParams}`,
    {
      ...FETCH_DEFAULT_CONFIGS_TOKEN(),
      method: "GET",
    }
  );

  return savingAccountTransactionsResponseDecoder.verify(await response.json());
}

export async function getCertificateDetails(
  accountNumber: string | number,
  from = "",
  to = ""
): Promise<CertificateDetailsResponse> {
  const searchParams = new URLSearchParams({ from, to }).toString();
  const response = await enhancedFetch(`${API_URL}/finance/certificate/${accountNumber}/?${searchParams}`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });

  return certificateDetailsResponseDecoder.verify(await response.json());
}

export async function getCertificateTransactions(
  accountNumber: string | number,
  from = "",
  to = ""
): Promise<CertificateTransactionsResponse> {
  const searchParams = new URLSearchParams({ from, to }).toString();
  const response = await enhancedFetch(
    `${API_URL}/finance/certificate-transactions/${accountNumber}/?${searchParams}`,
    {
      ...FETCH_DEFAULT_CONFIGS_TOKEN(),
      method: "GET",
    }
  );

  return certificateTransactionsResponseDecoder.verify(await response.json());
}

export async function getLoanDetails(accountNumber: string | number, from = "", to = ""): Promise<LoanDetailsResponse> {
  const searchParams = new URLSearchParams({ from, to }).toString();
  const response = await enhancedFetch(`${API_URL}/finance/loan/${accountNumber}/?${searchParams}`, {
    ...FETCH_DEFAULT_CONFIGS_TOKEN(),
    method: "GET",
  });
  const data = loanDetailsResponseDecoder.verify(await response.json());

  return data;
}
