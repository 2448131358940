import { Typography, Link, Box, Stack } from "@mui/material";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { useRegistrationContext } from "../Registration";
import logo from "../../../../../public/Blanco.png";
import { Fragment } from "react";

export default function Complete(): JSX.Element {
  const [{ isAffiliatedClient }] = useRegistrationContext();

  const Message = isAffiliatedClient ? AffiliateMessage : NonAffiliateMessage;
  return (
    <SplitFormContainer
      title="Registro completado"
      sideInfoTop={
        <Stack direction="column" justifyContent="center" alignItems="center" height="100%" width="100%">
          <Box src={logo} sx={{ height: 150 }} component="img" alt="Cooperativa Barcelona letter logo" />
        </Stack>
      }
      sideInfoBottom={null}
      form={<Message />}
    />
  );
}

function AffiliateMessage() {
  return (
    <Typography>
      <strong>¡Felicidades!</strong> tu usuario virtual ha sido creado exitosamente. El siguiente paso es la firma del
      documento de uso de canales electrónicos, puedes agendar una cita para la firma accediendo al siguiente enlace{" "}
      <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/haz-tu-cita/ ">
        <strong>Haz Tu Cita.</strong>
      </Link>
    </Typography>
  );
}

function NonAffiliateMessage() {
  return (
    <Fragment>
      <Typography>
        Su proceso de afiliación ha sido culminado con éxito, datos registrados y de usuario se estarán habilitando una
        vez se culmine el proceso y le servirá en un futuro para realizar transacciones digitales a través de nuestros
        canales electrónicos. Pasos por seguir:
      </Typography>
      <ol>
        <li>Un representante de negocios le estará contactando para la recolección de los datos.</li>
        <li>Un representante de RRHH de tu compañía deberá confirma la veracidad de los datos ingresados.</li>
        <li>Una vez verificado, serás aceptado y admitido como socio por nuestra cooperativa.</li>
        <li>Firmar los documentos de afiliación necesarios.</li>
        <li>Realizar depósito de afiliación según el método de pago elegido.</li>
      </ol>
    </Fragment>
  );
}
