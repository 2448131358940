import { ButtonTypeMap, Button } from "@mui/material";

export default function CompanyAppointmentLink(props: ButtonTypeMap<unknown, "button">["props"]) {
  return (
    <Button
      fullWidth
      target="_blank"
      rel="noopener"
      href="https://coopbarcelona.com/formulario-empresa/"
      variant="outlined"
      {...props}
    >
      Agendar Cita
    </Button>
  );
}
