import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS } from "../config";
import { OtpChannelValidationResponse, otpChannelValidationResponseDecoder, OtpValidationData } from "./interfaces";

export async function validateEmail(to: OtpValidationData["to"]): Promise<OtpChannelValidationResponse> {
  const response = await enhancedFetch(`${API_URL}/otp/email/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ to }),
  });

  return otpChannelValidationResponseDecoder.verify(await response.json());
}

export async function validatePhone(to: `+1${string}`): Promise<OtpChannelValidationResponse> {
  const response = await enhancedFetch(`${API_URL}/otp/phone/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ to }),
  });

  return otpChannelValidationResponseDecoder.verify(await response.json());
}

export async function validateOtp(otpValidationData: OtpValidationData): Promise<OtpChannelValidationResponse> {
  const response = await enhancedFetch(`${API_URL}/otp/validate/code/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(otpValidationData),
  });

  return otpChannelValidationResponseDecoder.verify(await response.json());
}
