import Cookies from "js-cookie";

export const FETCH_DEFAULT_CONFIGS: RequestInit = {
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCSRFToken(),
  },
};

export const FETCH_DEFAULT_CONFIGS_TOKEN = (): RequestInit => ({
  headers: {
    ...FETCH_DEFAULT_CONFIGS.headers,
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  },
});

export function getCSRFToken() {
  return Cookies.get("csrftoken") || "";
}
