import { AddressFormOptions } from "Services/api/crm/interfaces";
import { ClientFormData } from "Services/api/register/interfaces";
import { requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export type AddressFormProps<T> = {
  options: AddressFormOptions;
  onBack?: () => void;
  onSubmit: (values: T, form: keyof ClientFormData) => void | Promise<void>;
  initialValues: T;
};

const addressSimplifed = {
  country: requiredValidation,
  region: requiredValidation,
  province: requiredValidation,
  city: requiredValidation,
  sector: requiredValidation,
  line1: requiredValidation,
};

export const addressSimplifiedSchema = Yup.object(addressSimplifed);

export const clientFormSchema = {
  personal: Yup.object({
    firstName: requiredValidation,
    lastName: requiredValidation,
    dateOfBirth: requiredValidation,
    nationality: requiredValidation,
    profession: requiredValidation,
  }),
  address: Yup.object({
    ...addressSimplifed,
    line2: Yup.string(),
    addressCert: Yup.mixed(),
  }),
  work: Yup.object({
    jobStatus: requiredValidation,
    salary: requiredNumberValidation,
    dateOfRetirement: Yup.string(),
    dateOfHire: requiredValidation,
    jobType: requiredValidation,
    paymentType: requiredValidation,
    sessionType: requiredValidation,
    position: requiredValidation,
  }),
  finance: Yup.object({
    affiliationPurpose: Yup.object({
      financing: Yup.boolean(),
      savings: Yup.boolean(),
      certificate: Yup.boolean(),
    }),
    savingAmount: requiredNumberValidation,
    typeOfPayment: requiredValidation,
    numberOfPayments: Yup.string(),
    typeOfInstallments: Yup.string(),
    terms: Yup.boolean().isTrue("Debe leer y aceptar los terminos y condiciones"),
  }),
};
