import CompanyAppointmentLink from "../CompanyAppointmentLink/CompanyAppointmentLink";
import InfoIcon from "../../icons/InfoIcon";
import { Stack, Card, Typography } from "@mui/material";

export default function CompanyRegistrationCard() {
  return (
    <Stack
      spacing={2}
      component={Card}
      padding="30px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      elevation={5}
    >
      <InfoIcon />
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "25px",
          color: "#547725",
        }}
      >
        ¿Aún tu empresa no está afiliada?
      </Typography>
      <Typography component="p">
        Suminístranos los contactos de tu empresa para comunicarnos con el departamento de gestión humana e iniciar el
        proceso de afiliación.
      </Typography>
      <CompanyAppointmentLink />
    </Stack>
  );
}
