import enhancedFetch from "Utils/enhancedFetch";
import { API_URL } from "Utils/envVariables";
import { FETCH_DEFAULT_CONFIGS, getCSRFToken } from "../config";
import { MetamapVerification } from "../metamap/interfaces";
import { OtpValidationData } from "../otp/interfaces";
import {
  ClientFormData,
  ClientFormOptionsResponse,
  clientFormOptionsResponseDecoder,
  ConfirmChannelResponse,
  confirmChannelResponseDecoder,
  GetClientDataResponse,
  getClientDataResponseDecoder,
  GetClientIdResponse,
  getClientIdResponseDecoder,
  GetRegistrationResponse,
  getRegistrationResponseDecoder,
  NoEmployerCodePayload,
  NoEmployerCodeResponse,
  NoEmployerCodeResponseDecoder,
  RegistrationModel,
  RegistrationStatusResponse,
  registrationStatusResponseDecoder,
  ResetRegistrationResponse,
  resetRegistrationResponseDecoder,
  SaveClientDataResponse,
  saveClientDataResponseDecoder,
  SaveContactRequest,
  SaveContactResponse,
  saveContactResponseDecoder,
  SaveMetamapInfoResponse,
  saveMetamapInfoResponseDecoder,
  StartRegistrationRequest,
  StartRegistrationResponse,
  startRegistrationResponseDecoder,
  UserFormData,
} from "./interfaces";

const ENDPOINT = `${API_URL}/register`;

export async function startRegistration(
  startRegistrationRequest: StartRegistrationRequest
): Promise<StartRegistrationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/start/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(startRegistrationRequest),
  });

  return startRegistrationResponseDecoder.verify(await response.json());
}

export async function startNoEmployerCode(
  noEmployerCodePayload: NoEmployerCodePayload
): Promise<NoEmployerCodeResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/start-with-no-employer-code/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(noEmployerCodePayload),
  });

  return NoEmployerCodeResponseDecoder.verify(await response.json());
}

export async function resetRegistration(id: RegistrationModel["id"]): Promise<ResetRegistrationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/reset/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return resetRegistrationResponseDecoder.verify(await response.json());
}

export async function confirmEmail(
  id: RegistrationModel["id"],
  to: OtpValidationData["to"]
): Promise<ConfirmChannelResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/confirm-email/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, to }),
  });

  return confirmChannelResponseDecoder.verify(await response.json());
}

export async function confirmPhone(
  id: RegistrationModel["id"],
  to: OtpValidationData["to"]
): Promise<ConfirmChannelResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/confirm-phone/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, to }),
  });

  return confirmChannelResponseDecoder.verify(await response.json());
}

export async function saveEmail(saveContactRequest: SaveContactRequest): Promise<SaveContactResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-email/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(saveContactRequest),
  });

  return saveContactResponseDecoder.verify(await response.json());
}

export async function savePhone(saveContactRequest: SaveContactRequest): Promise<SaveContactResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-phone/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify(saveContactRequest),
  });

  return saveContactResponseDecoder.verify(await response.json());
}

export async function saveMetamapInfo(
  id: RegistrationModel["id"],
  metamapVerification: MetamapVerification
): Promise<SaveMetamapInfoResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-metamap-info/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, metamapId: metamapVerification.id, metamapIdentity: metamapVerification.identity }),
  });

  return saveMetamapInfoResponseDecoder.verify(await response.json());
}

export async function getClientFormOptions(): Promise<ClientFormOptionsResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-form-options/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return clientFormOptionsResponseDecoder.verify(await response.json());
}

export async function getClientData(id: RegistrationModel["id"]): Promise<GetClientDataResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return getClientDataResponseDecoder.verify(await response.json());
}

export async function savePersonalData(
  id: RegistrationModel["id"],
  personalData: ClientFormData["personal"]
): Promise<SaveClientDataResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-personal-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, ...personalData }),
  });

  return saveClientDataResponseDecoder.verify(await response.json());
}

export async function saveAddressData(data: FormData): Promise<SaveClientDataResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-address-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return saveClientDataResponseDecoder.verify(await response.json());
}

export async function saveWorkData(data: FormData): Promise<SaveClientDataResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-work-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
    method: "POST",
    body: data,
  });

  return saveClientDataResponseDecoder.verify(await response.json());
}

export async function saveFinanceData(
  id: RegistrationModel["id"],
  financeData: ClientFormData["finance"]
): Promise<RegistrationStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/save-finance-data/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, ...financeData }),
  });

  return registrationStatusResponseDecoder.verify(await response.json());
}

export async function getClientId(id: RegistrationModel["id"]): Promise<GetClientIdResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/get-client-id/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id }),
  });

  return getClientIdResponseDecoder.verify(await response.json());
}

export async function completeRegistration(
  id: RegistrationModel["id"],
  userData: UserFormData
): Promise<RegistrationStatusResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/complete/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "POST",
    body: JSON.stringify({ id, ...userData }),
  });

  return registrationStatusResponseDecoder.verify(await response.json());
}

export async function getRegistration(id: string): Promise<GetRegistrationResponse> {
  const response = await enhancedFetch(`${ENDPOINT}/${id}/`, {
    ...FETCH_DEFAULT_CONFIGS,
    method: "GET",
  });

  return getRegistrationResponseDecoder.verify(await response.json());
}
