import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues, useField, useFormikContext } from "formik";
import InfoIcon from "Icons/InfoIcon";
import { Fragment, useEffect, useState } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { ClientFormData, ClientFormOptions } from "Services/api/register/interfaces";
import FormikCheckbox from "Shared/FormikCheckbox/FormikCheckbox";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { LabeledTextField } from "Shared/LabeledTextField/LabeledTextField";
import { useRegistrationContext } from "../Registration";
import { clientFormSchema, StepFormProps } from "./interfaces";

export function FinanceForm<T extends FormikValues>(props: StepFormProps<T>) {
  const { onSubmit, onBack, initialValues, options } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: T, { setSubmitting }: FormikHelpers<T>) => {
        onSubmit(values, "finance");
        setSubmitting(false);
      }}
      validationSchema={clientFormSchema.finance}
    >
      <Grid container width="100%" spacing={2} component={Form}>
        <Grid item sm={12} width="100%">
          <FormikAffiliationPurpose name="affiliationPurpose" />
        </Grid>
        <Grid item sm={6} width="100%">
          <SavingAmount />
        </Grid>
        <Grid item sm={6} width="100%">
          <FormikTextField
            name="inputAmount"
            id="inputAmount"
            fullWidth
            label="Cantidad de aportaciones:"
            variant="outlined"
            type="number"
            step="1"
            min="1"
            required
          />
        </Grid>
        <Grid item sm={6} width="100%">
          <TotalAmount />
        </Grid>
        <Grid item sm={6} width="100%">
          <InitialDeposit />
        </Grid>

        <Payment options={options} />

        <Grid item sm={12} width="100%">
          <FormikCheckbox
            name="terms"
            label={
              <Typography>
                Yo acepto y autorizo el registro de mi información para el propósito descrito en los{" "}
                <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/condiciones-uso-digital/">
                  Terminos y condiciones
                </Link>
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={6} width="100%">
          <Button fullWidth variant="outlined" onClick={onBack}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} width="100%">
          <FormikSubmitButton fullWidth variant="contained">
            Finalizar
          </FormikSubmitButton>
        </Grid>
      </Grid>
    </Formik>
  );
}

function TotalAmount() {
  const [{ value }] = useField<ClientFormData["finance"]["inputAmount"]>("inputAmount");

  const numericConfig = {
    allowNegative: false,
    decimalScale: 2,
    thousandSeparator: ",",
  };
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);

  return (
    <NumericFormat
      fullWidth
      label="Total de aportaciones:"
      variant="outlined"
      value={format((value * 2000).toString())}
      required
      disabled
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customInput={LabeledTextField}
      {...numericConfig}
    />
  );
}

function SavingAmount() {
  const [{ value }, , { setValue }] = useField<ClientFormData["finance"]["savingAmount"]>("savingAmount");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const numericConfig = {
    allowNegative: false,
    decimalScale: 2,
    thousandSeparator: ",",
  };
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  return (
    <>
      <NumericFormat
        name="savingAmount"
        id="savingAmount"
        fullWidth
        label="Monto mensual a ahorrar:"
        variant="outlined"
        value={value ? format(value.toString()) : ""}
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: (
            <InputAdornment component={ButtonBase} position="end" onClick={handleClick}>
              <InfoIcon width="22" height="22" />
            </InputAdornment>
          ),
        }}
        customInput={FormikTextField}
        {...numericConfig}
        onValueChange={({ floatValue }) => {
          setValue(floatValue ? floatValue : null);
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>Monto de ahorro mensual que desea realizar en la cooperativa</Typography>
      </Popover>
    </>
  );
}

function InitialDeposit() {
  const [{ value }, , { setValue }] = useField<ClientFormData["finance"]["initialDeposit"]>("initialDeposit");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [{ value: minInitialDeposit }] = useField<ClientFormData["finance"]["minInitialDeposit"]>("minInitialDeposit");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const numericConfig = {
    allowNegative: false,
    decimalScale: 2,
    thousandSeparator: ",",
  };
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  return (
    <>
      <NumericFormat
        name="initialDeposit"
        id="initialDeposit"
        fullWidth
        label="Depósito de ahorro inicial:"
        variant="outlined"
        value={value ? format(value.toString()) : ""}
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: (
            <InputAdornment component={ButtonBase} position="end" onClick={handleClick}>
              <InfoIcon width="22" height="22" />
            </InputAdornment>
          ),
        }}
        customInput={FormikTextField}
        {...numericConfig}
        onValueChange={({ floatValue }) => {
          setValue(floatValue ? floatValue : null);
        }}
      />
      {minInitialDeposit > 0 && (
        <FormHelperText>
          <strong>Cantidad minima:</strong> RD$ {format(minInitialDeposit.toString())}
        </FormHelperText>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>Monto del deposito inicial con el que desea abrir su cuenta de ahorro</Typography>
      </Popover>
    </>
  );
}

function Payment({
  options: { typesOfPayment, typesOfInstallment, numberOfMonthlyPayments, numberOfBiweeklyPayments },
}: {
  options: ClientFormOptions;
}) {
  const [{ type }] = useRegistrationContext();
  const [{ value: typeOfPayment }, ,] = useField<ClientFormData["finance"]["typeOfPayment"]>("typeOfPayment");
  const [{ value: typeOfInstallments }, , { setValue: setTypeOfInstallments }] =
    useField<ClientFormData["finance"]["typeOfInstallments"]>("typeOfInstallments");
  const [{ value: numberOfPayments }, , { setValue: setNumberOfPayments }] =
    useField<ClientFormData["finance"]["numberOfPayments"]>("numberOfPayments");
  const [, , { setValue: setMinInitialDeposit }] =
    useField<ClientFormData["finance"]["minInitialDeposit"]>("minInitialDeposit");

  useEffect(() => {
    if (typeOfPayment === "payroll_deduction") {
      setMinInitialDeposit(500);
    } else if (typeOfPayment) {
      setMinInitialDeposit(1100);
      if (typeOfInstallments) setTypeOfInstallments("");
      if (numberOfPayments) setNumberOfPayments("");
    } else {
      setMinInitialDeposit(0);
      if (typeOfInstallments) setTypeOfInstallments("");
      if (numberOfPayments) setNumberOfPayments("");
    }
  }, [typeOfPayment]);

  useEffect(() => {
    setNumberOfPayments("");
  }, [typeOfInstallments]);

  return (
    <Fragment>
      <Grid item sm={6} width="100%">
        <FormikSelect id="typeOfPayment" name="typeOfPayment" label="Forma de pago:" required>
          {typesOfPayment.map(([value, display]) => {
            if (type !== "normal" && value === "payroll_deduction") return null;

            return (
              <MenuItem key={value} value={value}>
                {display}
              </MenuItem>
            );
          })}
        </FormikSelect>
      </Grid>

      {typeOfPayment === "payroll_deduction" && (
        <Fragment>
          <Grid item sm={6} width="100%">
            <FormikSelect id="typeOfInstallments" name="typeOfInstallments" label="Forma de pago afiliación:" required>
              {typesOfInstallment.map(([value, display]) => (
                <MenuItem key={value} value={value}>
                  {display}
                </MenuItem>
              ))}
            </FormikSelect>
          </Grid>
          <Grid item sm={6} width="100%">
            <FormikSelect
              id="numberOfPayments"
              name="numberOfPayments"
              label="Número de cuotas:"
              required
              helperText="Cantidad de pagos con la cual entiendas quieras completar tu certificado de aportación."
            >
              {typeOfInstallments === "monthly" &&
                numberOfMonthlyPayments.map(([value, display]) => (
                  <MenuItem key={value} value={value}>
                    {display}
                  </MenuItem>
                ))}
              {typeOfInstallments === "biweekly" &&
                numberOfBiweeklyPayments.map(([value, display]) => (
                  <MenuItem key={value} value={value}>
                    {display}
                  </MenuItem>
                ))}
            </FormikSelect>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
}

interface FormikAffiliationPurposeProps {
  name: string;
}

function FormikAffiliationPurpose(props: FormikAffiliationPurposeProps) {
  const [{ value }, , { setValue }] = useField<ClientFormData["finance"]["affiliationPurpose"]>(props);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[props.name]) && Boolean(formikErrors[props.name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [event.target.name]: event.target.checked,
    });
  };

  const { financing, savings, certificate } = value;
  const checkLabelStyle = {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    color: "#383838",
  };

  return (
    <FormControl component="fieldset" variant="standard" disabled={isSubmitting}>
      <FormLabel
        component="legend"
        sx={{
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "25px",
          color: "#7C7C7C",
        }}
      >
        Propósito de afiliacion:
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={financing} onChange={handleChange} name="financing" />}
          label={<Typography sx={{ checkLabelStyle }}>Financiamiento</Typography>}
        />

        <FormControlLabel
          control={<Checkbox checked={savings} onChange={handleChange} name="savings" />}
          label={<Typography sx={{ checkLabelStyle }}>Ahorros</Typography>}
        />

        <FormControlLabel
          control={<Checkbox checked={certificate} onChange={handleChange} name="certificate" />}
          label={<Typography sx={{ checkLabelStyle }}>Certificado de Inversión</Typography>}
        />
      </FormGroup>
      {formikError ? <FormHelperText error> {formikErrors[props.name]}</FormHelperText> : null}
    </FormControl>
  );
}
