import {
  array,
  boolean,
  constant,
  Decoder,
  DecoderType,
  either,
  nullable,
  number,
  object,
  optional,
  string,
  tuple,
  undefined_,
  positiveInteger,
} from "decoders";
import { JsendResponse, jsendResponseDecoder, JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";
import {
  baseOptionDecoder,
  departmentOptionDecoder,
  nationalityOptionDecoder,
  positionOptionDecoder,
  professionOptionDecoder,
  regionOptionDecoder,
} from "../crm/interfaces";
import { OtpValidationData } from "../otp/interfaces";

const registrationStatusDecoder = either(
  constant("client-verification"),
  constant("email-validation"),
  constant("phone-validation"),
  constant("identity-verification"),
  constant("client-configuration"),
  constant("user-configuration"),
  constant("complete")
);

const registrationModelDecoder = object({
  id: string,
  status: registrationStatusDecoder,
  type: either(constant("normal"), constant("salaried"), constant("independent")),
  isAffiliatedClient: boolean,
  partialEmail: optional(string),
  partialNumber: optional(string),
  isResumable: boolean,
});

export type RegistrationModel = DecoderType<typeof registrationModelDecoder>;

export interface StartRegistrationRequest {
  clientId: string;
  employerCode: string;
}

const startRegistrationResponseFailDecoder = object({
  errorKey: either(
    constant("invalidEmployerCode"),
    constant("nonActiveAffiliatedClient"),
    constant("affiliatedClientWithAccess"),
    constant("IndependentEmployerCodeWithNoClientData"),
    constant("IndependentClientWithIncorrectData")
  ),
  message: string,
});

export type StartRegistrationResponseFail = DecoderType<typeof startRegistrationResponseFailDecoder>;

export type StartRegistrationResponse = JsendResponse<RegistrationModel, StartRegistrationResponseFail>;

export const startRegistrationResponseDecoder = jsendResponseDecoder(
  registrationModelDecoder,
  startRegistrationResponseFailDecoder
);

export type ResetRegistrationResponse = JsendSuccess<RegistrationModel>;
export const resetRegistrationResponseDecoder = jsendSuccessDecoder(registrationModelDecoder);

export type ConfirmChannelResponse = JsendResponse<string, string>;

export const confirmChannelResponseDecoder: Decoder<ConfirmChannelResponse> = jsendResponseDecoder(string, string);

export interface SaveContactRequest extends OtpValidationData {
  id: RegistrationModel["id"];
}

export type SaveContactResponse = JsendResponse<{ status: RegistrationModel["status"] }, string>;

export const saveContactResponseDecoder: Decoder<SaveContactResponse> = jsendResponseDecoder(
  object({
    status: registrationStatusDecoder,
  }),
  string
);

export type SaveMetamapInfoResponse = SaveContactResponse;

export const saveMetamapInfoResponseDecoder: Decoder<SaveMetamapInfoResponse> = saveContactResponseDecoder;

const clientFormOptionsDecoder = object({
  nationalities: array(nationalityOptionDecoder),
  professions: array(professionOptionDecoder),
  regions: array(regionOptionDecoder),
  jobStatus: array(tuple(string, string)),
  jobTypes: array(tuple(string, string)),
  paymentTypes: array(tuple(string, string)),
  sessionTypes: array(tuple(string, string)),
  positions: array(positionOptionDecoder),
  departments: array(departmentOptionDecoder),
  economicSectors: array(baseOptionDecoder),
  typesOfPayment: array(tuple(string, string)),
  typesOfInstallment: array(tuple(string, string)),
  numberOfMonthlyPayments: array(tuple(string, string)),
  numberOfBiweeklyPayments: array(tuple(string, string)),
});

export type ClientFormOptions = DecoderType<typeof clientFormOptionsDecoder>;

export type ClientFormOptionsResponse = JsendSuccess<ClientFormOptions>;
export const clientFormOptionsResponseDecoder: Decoder<ClientFormOptionsResponse> =
  jsendSuccessDecoder(clientFormOptionsDecoder);

export const PersonalFormDataDecoder = object({
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  nationality: string,
  profession: string,
});

export const AddressFormDataDecoder = object({
  country: string,
  region: string,
  province: string,
  city: string,
  sector: string,
  line1: string,
  line2: string,
  addressCert: nullable(undefined_),
});

export const WorkFormDataDecoder = object({
  jobStatus: string,
  salary: nullable(number),
  dateOfRetirement: string,
  dateOfHire: string,
  jobType: string,
  paymentType: string,
  sessionType: string,
  position: string,
  department: string,
  rnc: string,
  institutionName: string,
  institutionEmail: string,
  institutionPhone: string,
  institutionAddress: string,
  economicSector: string,
  commercialActivity: string,
  proofOfIncome: nullable(undefined_),
});

export const FinanceFormDataDecoder = object({
  affiliationPurpose: object({
    financing: boolean,
    savings: boolean,
    certificate: boolean,
  }),
  savingAmount: nullable(number),
  inputAmount: positiveInteger,
  minInitialDeposit: positiveInteger,
  initialDeposit: nullable(number),
  typeOfPayment: either(
    constant("office"),
    constant("transfer_or_deposit"),
    constant("payroll_deduction"),
    constant("")
  ),
  numberOfPayments: string,
  typeOfInstallments: either(constant(""), constant("monthly"), constant("biweekly")),
  terms: boolean,
});

const clientFormDataDecoder = object({
  personal: PersonalFormDataDecoder,
  address: AddressFormDataDecoder,
  work: WorkFormDataDecoder,
  finance: FinanceFormDataDecoder,
});

export type ClientFormData = DecoderType<typeof clientFormDataDecoder>;

export type GetClientDataResponse = JsendSuccess<ClientFormData>;
export const getClientDataResponseDecoder = jsendSuccessDecoder(clientFormDataDecoder);

export type SaveClientDataResponse = JsendSuccess<string>;
export const saveClientDataResponseDecoder: Decoder<SaveClientDataResponse> = jsendSuccessDecoder(string);

export type RegistrationStatusResponse = JsendSuccess<{ status: RegistrationModel["status"] }>;

export const registrationStatusResponseDecoder: Decoder<RegistrationStatusResponse> = jsendSuccessDecoder(
  object({ status: registrationStatusDecoder })
);

const getClientIdDecoder = object({
  clientId: string,
});

export type GetClientId = DecoderType<typeof getClientIdDecoder>;

export type GetClientIdResponse = JsendSuccess<GetClientId>;
export const getClientIdResponseDecoder = jsendSuccessDecoder(getClientIdDecoder);

export interface UserFormData {
  password: string;
  confirm: string;
  question1: string;
  answer1: string;
  question2: string;
  answer2: string;
}

export interface ReadOnlySecurityQuestion {
  name: string;
  unique_id: string;
}

export interface ReadOnlyUserFormData {
  username: string;
  securityQuestions: ReadOnlySecurityQuestion[];
}

export type GetRegistrationResponse = JsendResponse<RegistrationModel, string>;

export const getRegistrationResponseDecoder = jsendResponseDecoder(registrationModelDecoder, string);

export interface NoEmployerCodePayload {
  to: string;
  code: string;
  clientId: string;
  name: string;
  rnc: string;
  salaried: boolean;
}

export const NoEmployerCodeResponseDecoder = jsendResponseDecoder(string, string);

export type NoEmployerCodeResponse = DecoderType<typeof NoEmployerCodeResponseDecoder>;
