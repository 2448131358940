import { Clear } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useHandleError } from "Hooks/useHandleError";
import OtpValidationForm from "Pages/Registration/OtpValidation/OtpValidationForm";
import { useState } from "react";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { validateEmail } from "Services/api/otp/otp";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { emailValidation } from "Utils/validations";
import * as Yup from "yup";

interface EmailDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: EmailFormProps["onSubmit"];
}

export function EmailDialog(props: EmailDialogProps) {
  const { open, onClose, onSubmit } = props;

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        Email
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <EmailForm onSubmit={onSubmit} />
        <Button onClick={onClose} sx={{ mt: "25px" }} fullWidth>
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  );
}

const schema = Yup.object({
  email: emailValidation,
});

interface Payload {
  email: string;
  code: string;
}

interface EmailFormProps {
  onSubmit: (payload: Payload) => Promise<void>;
}
function EmailForm({ onSubmit }: EmailFormProps) {
  const [step, setStep] = useState<"edit" | "otp">("edit");
  const [payload, setPayload] = useState<Payload>({ email: "", code: "" });
  const handleError = useHandleError();

  return step === "edit" ? (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={async ({ email }) => {
        await validateEmail(email);
        setPayload({ email, code: "" });
        setStep("otp");
      }}
      validationSchema={schema}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <FormikTextField
            name="email"
            id="email"
            type="email"
            fullWidth
            label="Correo electrónico:"
            variant="outlined"
            required
          />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : (
    <OtpValidationForm
      channel="email"
      channelDisplay="correo"
      to={payload.email}
      expiryTime={600}
      onSubmit={onEmailValidationSubmit}
    />
  );

  async function onEmailValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      await onSubmit({ email: payload.email, code });
    } catch (error) {
      handleError(error);
    }

    setSubmitting(false);
  }
}
