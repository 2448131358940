import { clientIdValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export const clientVerificationKeys: ["clientId", "employerCode"] = ["clientId", "employerCode"];

export const clientVerificationSchema = Yup.object({
  [clientVerificationKeys[0]]: clientIdValidation,
  [clientVerificationKeys[1]]: requiredValidation,
});

export function noEmployerCodeSchema(salaried: boolean) {
  if (salaried)
    return Yup.object({
      [clientVerificationKeys[0]]: clientIdValidation,
      name: requiredValidation,
      rnc: requiredValidation.matches(/^[0-9]{1}-?[0-9]{2}-?[0-9]{5}-?[0-9]{1}$/, "RNC invalido"),
    });

  return Yup.object({
    [clientVerificationKeys[0]]: clientIdValidation,
    name: requiredValidation,
    rnc: Yup.string(),
  });
}

export const noEmployerCodeDataCapturedSchema = Yup.object({
  [clientVerificationKeys[1]]: requiredValidation,
});
