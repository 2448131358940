import { Stack } from "@mui/material";
import CompanyRegistrationCard from "Shared/CompanyRegistrationCard/CompanyRegistrationCard";

export default function AccessRequirements() {
  return (
    <Stack spacing={2} direction="column" width="100%">
      <hr
        style={{
          border: "1px solid #FFE600",
          width: "95%",
        }}
      />
      <CompanyRegistrationCard />
    </Stack>
  );
}
