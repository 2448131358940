import { Clear } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import InfoIcon from "Icons/InfoIcon";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { useSnackbar } from "notistack";
import { ChangeEvent, Dispatch, Fragment, ReactNode, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  RegistrationModel,
  StartRegistrationRequest,
  StartRegistrationResponseFail,
} from "Services/api/register/interfaces";
import { resetRegistration, startNoEmployerCode, startRegistration } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import AccessRequirements from "Shared/AccessRequirements/AccessRequirements";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import CompanyAppointmentLink from "Shared/CompanyAppointmentLink/CompanyAppointmentLink";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { useRegistrationContext } from "../Registration";
import { EmailDialog } from "./EmailDialog";
import {
  clientVerificationKeys,
  clientVerificationSchema,
  noEmployerCodeDataCapturedSchema,
  noEmployerCodeSchema,
} from "./interfaces";

export default function ClientVerification(): JSX.Element {
  return (
    <SplitFormContainer
      title="Solicitud de afiliación"
      sideInfoTop={<AccessInfo />}
      sideInfoBottom={<AccessRequirements />}
      form={<ClientVerificationForm />}
    />
  );
}

const initialValues: StartRegistrationRequest = {
  [clientVerificationKeys[0]]: "",
  [clientVerificationKeys[1]]: "",
};

function ClientVerificationForm(): JSX.Element {
  const [openInactiveDialog, setOpenInactiveDialog] = useState(false);
  const [isValidEmployerCode, setIsValidEmployerCode] = useState(true);
  const [openResumeDialog, setOpenResumeDialog] = useState(false);
  const [resumableData, setResumableData] = useState<RegistrationModel>();
  const [noEmployerCodeDataSubmitted, setNoEmployerCodeDataSubmitted] = useState(false);
  const [error, setError] = useState("");

  const onClientVerificationSubmit = useOnClientVerificationSubmit(
    setResumableData,
    setOpenResumeDialog,
    setIsValidEmployerCode,
    setOpenInactiveDialog,
    setError
  );

  const noEmployerCodeFormProps: NoEmployerCodeFormProps = {
    isValidEmployerCode,
    onClientVerificationSubmit,
    noEmployerCodeDataSubmitted,
    setNoEmployerCodeDataSubmitted,
  };

  return (
    <Stack spacing={2} width="100%">
      <RadioQuestion
        question="¿Tienes código de empleador?"
        hideOptions={noEmployerCodeDataSubmitted}
        FirstElement={
          <EmployerCodeForm
            {...{
              isValidEmployerCode,
              openResumeDialog,
              setOpenResumeDialog,
              resumableData,
              onClientVerificationSubmit,
            }}
          />
        }
        SecondElement={
          <RadioQuestion
            question="¿Tu empresa está afiliada?"
            hideOptions={noEmployerCodeDataSubmitted}
            FirstElement={
              <p>
                Puedes adquirir el código de empleador contactando tu departamento de gestión humana o comunicándote con
                uno de nuestros oficiales través de{" "}
                <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
                  <strong>nuestros canales de comunicación.</strong>
                </Link>
              </p>
            }
            SecondElement={
              <RadioQuestion
                question="¿Eres asalariado o independiente?"
                hideOptions={noEmployerCodeDataSubmitted}
                FirstElement={<NoEmployerCodeForm salaried {...noEmployerCodeFormProps} />}
                SecondElement={<NoEmployerCodeForm {...noEmployerCodeFormProps} />}
                options={["Asalariado", "Independiente"]}
              />
            }
            options={["Si", "No"]}
          />
        }
        options={["Si", "No"]}
        defaultValue={"0"}
      />
      {Boolean(error) && <p style={{ color: "darkred" }}>{error}</p>}
      <InactiveAffiliatedClientDialog open={openInactiveDialog} onClose={() => setOpenInactiveDialog(false)} />
    </Stack>
  );
}

function useOnClientVerificationSubmit(
  setResumableData: Dispatch<RegistrationModel>,
  setOpenResumeDialog: Dispatch<boolean>,
  setIsValidEmployerCode: Dispatch<boolean>,
  setOpenInactiveDialog: Dispatch<boolean>,
  setError: Dispatch<string>
) {
  const { enqueueSnackbar } = useSnackbar();
  const [, setRegContext] = useRegistrationContext();
  const handleFail = useHandleFail(setIsValidEmployerCode, setOpenInactiveDialog, setError);

  return async function onClientVerificationSubmit(
    values: StartRegistrationRequest,
    setSubmitting: (isSubmitting: boolean) => void,
    enableResume = true
  ) {
    try {
      setError("");
      const { status, data } = await startRegistration(values);

      if (status === "success") {
        if (enableResume && data.isResumable) {
          setResumableData(data);
          setOpenResumeDialog(true);
        } else setRegContext(data);
      } else handleFail(data, values.clientId);
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error en la comunicación con el servidor", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  };
}

interface EmployerCodeFormProps {
  isValidEmployerCode: boolean;
  openResumeDialog: boolean;
  setOpenResumeDialog: Dispatch<boolean>;
  resumableData?: RegistrationModel;
  onClientVerificationSubmit(
    values: StartRegistrationRequest,
    setSubmitting: (isSubmitting: boolean) => void,
    enableResume?: boolean
  ): Promise<void>;
}

function EmployerCodeForm({
  isValidEmployerCode,
  openResumeDialog,
  setOpenResumeDialog,
  resumableData,
  onClientVerificationSubmit,
}: EmployerCodeFormProps) {
  const [, setRegContext] = useRegistrationContext();

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={clientVerificationSchema}
      onSubmit={(values, { setSubmitting }) => onClientVerificationSubmit(values, setSubmitting)}
    >
      <FormikForm width="100%">
        <ClientIdInput name={clientVerificationKeys[0]} id={clientVerificationKeys[0]} />
        <FormikTextField
          name={clientVerificationKeys[1]}
          id={clientVerificationKeys[1]}
          fullWidth
          label="Código de empleador:"
          variant="outlined"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment component={ButtonBase} position="end" onClick={handleClick}>
                <InfoIcon width="22" height="22" />
              </InputAdornment>
            ),
          }}
          {...(!isValidEmployerCode
            ? {
                error: true,
                helperText: (
                  <>
                    <span>Código de empleador invalido.</span>
                    <br />
                    <span>Puede reintentar o hacer click abajo para agendar una cita para afiliar tu empresa.</span>
                  </>
                ),
              }
            : {})}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>
            Si aún no tienes este código, consulta con el departamento de gestión humana de tu empresa.
          </Typography>
        </Popover>
        {!isValidEmployerCode ? <CompanyAppointmentLink variant="text" /> : null}

        <ResumeRegistrationDialog
          open={openResumeDialog}
          onClose={() => setOpenResumeDialog(false)}
          onReset={async () => {
            try {
              if (!resumableData) throw new Error("Ha ocurrido un error");
              const { data } = await resetRegistration(resumableData.id);

              setRegContext(data);
            } catch (error) {
              enqueueSnackbar("Ha ocurrido un error en la comunicación con el servidor", { variant: "error" });
              console.error(error);
            }
            setOpenResumeDialog(false);
          }}
          onResume={() => {
            if (resumableData) setRegContext(resumableData);
            else enqueueSnackbar("Ha ocurrido un error");
            setOpenResumeDialog(false);
          }}
        />
        <FormikSubmitButton fullWidth variant="contained">
          {isValidEmployerCode ? "Siguiente" : "Reintentar"}
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );
}

const noEmployerCodeFormInit = {
  [clientVerificationKeys[0]]: "",
  name: "",
  rnc: "",
};

interface NoEmployerCodeFormProps {
  salaried?: boolean;
  onClientVerificationSubmit: EmployerCodeFormProps["onClientVerificationSubmit"];
  isValidEmployerCode: boolean;
  noEmployerCodeDataSubmitted: boolean;
  setNoEmployerCodeDataSubmitted: Dispatch<boolean>;
}

function NoEmployerCodeForm({
  salaried = false,
  onClientVerificationSubmit,
  isValidEmployerCode,
  noEmployerCodeDataSubmitted,
  setNoEmployerCodeDataSubmitted,
}: NoEmployerCodeFormProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [payload, setPayload] = useState(noEmployerCodeFormInit);
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  return noEmployerCodeDataSubmitted ? <CodeForm /> : <DataForm />;

  function CodeForm() {
    return (
      <Fragment>
        <p>
          Para continuar favor de introducir el codigo de empleador que hemos enviado a <strong>{email}</strong>.
        </p>
        <Formik
          initialValues={{
            [clientVerificationKeys[1]]: "",
          }}
          validationSchema={noEmployerCodeDataCapturedSchema}
          onSubmit={({ employerCode }, { setSubmitting }) =>
            onClientVerificationSubmit(
              {
                clientId: payload.clientId,
                employerCode,
              },
              setSubmitting,
              false
            )
          }
        >
          <FormikForm width="100%">
            <FormikTextField
              name={clientVerificationKeys[1]}
              id={clientVerificationKeys[1]}
              fullWidth
              label="Código de empleador:"
              variant="outlined"
              required
              {...(!isValidEmployerCode
                ? {
                    error: true,
                    helperText: <span>Código de empleador invalido.</span>,
                  }
                : {})}
            />
            <FormikSubmitButton fullWidth variant="contained">
              Siguiente
            </FormikSubmitButton>
          </FormikForm>
        </Formik>
      </Fragment>
    );
  }

  function DataForm() {
    return (
      <Fragment>
        <Formik
          initialValues={noEmployerCodeFormInit}
          validationSchema={noEmployerCodeSchema(salaried)}
          onSubmit={(values, { setSubmitting }) => {
            setPayload(values);
            setOpenDialog(true);
            setSubmitting(false);
          }}
        >
          <FormikForm width="100%">
            <ClientIdInput name={clientVerificationKeys[0]} id={clientVerificationKeys[0]} />
            <FormikTextField name="name" id="name" fullWidth label="Nombre:" variant="outlined" required />

            {salaried && (
              <FormikTextField name="rnc" id="rnc" fullWidth label="RNC de tu empresa:" variant="outlined" required />
            )}

            <FormikSubmitButton fullWidth variant="contained">
              Siguiente
            </FormikSubmitButton>
          </FormikForm>
        </Formik>
        <EmailDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onSubmit={async ({ email, code }) => {
            const { status, data } = await startNoEmployerCode({
              ...payload,
              to: email,
              code,
              salaried: Boolean(salaried),
            });

            if (status === "success") {
              enqueueSnackbar(data, { variant: "success" });
              setOpenDialog(false);
              setNoEmployerCodeDataSubmitted(true);
              setEmail(email);
            } else enqueueSnackbar(data, { variant: "error" });
          }}
        />
      </Fragment>
    );
  }
}

interface RadioQuestionProps {
  question: string;
  options: [string, string];
  FirstElement: ReactNode;
  SecondElement: ReactNode;
  defaultValue?: string;
  hideOptions?: boolean;
}

function RadioQuestion({
  question,
  options,
  FirstElement,
  SecondElement,
  defaultValue,
  hideOptions = false,
}: RadioQuestionProps) {
  const [value, setValue] = useState(defaultValue ? defaultValue : "");

  return (
    <Stack spacing={2}>
      {!hideOptions && (
        <FormControl>
          <FormLabel>{question}</FormLabel>
          <RadioGroup
            row
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setValue((event.target as HTMLInputElement).value);
            }}
            {...(defaultValue !== undefined ? { defaultValue } : {})}
          >
            <FormControlLabel value="0" control={<Radio />} label={options[0]} />
            <FormControlLabel value="1" control={<Radio />} label={options[1]} />
          </RadioGroup>
        </FormControl>
      )}
      {value === "0" ? <>{FirstElement}</> : value === "1" ? <>{SecondElement}</> : null}
    </Stack>
  );
}

function useHandleFail(
  setIsValidEmployerCode: Dispatch<boolean>,
  setOpenDialog: Dispatch<boolean>,
  setError: Dispatch<string>
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return function handleFail(data: StartRegistrationResponseFail, clientId: string) {
    switch (data.errorKey) {
      case "invalidEmployerCode":
        setIsValidEmployerCode(false);
        enqueueSnackbar(data.message, { variant: "error" });
        return;

      case "nonActiveAffiliatedClient":
        setOpenDialog(true);
        enqueueSnackbar(data.message);
        return;

      case "affiliatedClientWithAccess":
        navigate({
          pathname: "/acceso/",
          search: createSearchParams({
            client: clientId,
          }).toString(),
        });
        enqueueSnackbar(data.message);
        return;

      case "IndependentEmployerCodeWithNoClientData":
        setError(
          `El codigo proporcionado es un codigo generado y enviado por correo electronico 
           pero la data del cliente con esta cédula no existe. 
           Favor seleccionar las opciones para enviar el codigo nuevamente`
        );
        return;

      case "IndependentClientWithIncorrectData":
        setError(
          `El codigo proporcionado es un codigo generado y enviado por correo electronico 
           pero la data del cliente con esta cédula esta incorrecta. 
           Favor seleccionar las opciones para enviar el codigo nuevamente.`
        );
        return;

      default:
        throw { error: "Non contemplated failure", data };
    }
  };
}

interface InactiveAffiliatedClientDialogProps {
  open: boolean;
  onClose: () => void;
}

function InactiveAffiliatedClientDialog(props: InactiveAffiliatedClientDialogProps) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Socio Inactivo</DialogTitle>

      <DialogContent>
        <p>Al parecer ya estas asociado en nuestra cooperativa pero tu afiliación no esta activa.</p>
        <p>
          Puedes dirigirte a{" "}
          <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
            <strong>nuestra pagina de contacto</strong>
          </Link>{" "}
          y solicitar ayuda en cualquiera de las vias de contacto que ofrecemos.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

interface ResumeRegistrationDialogProps {
  open: boolean;
  onClose: () => void;
  onReset: () => Promise<void>;
  onResume: () => void;
}

function ResumeRegistrationDialog(props: ResumeRegistrationDialogProps) {
  const { onClose, onReset, onResume, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        ¿Resumir registro?
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <p>Al parecer ya habias iniciado un proceso de registro.</p>
        <p>
          Puedes volver a iniciar un proceso nuevo o resumir el anterior dando click a una de las opciones de abajo.
        </p>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Button onClick={() => void onReset()} variant="text">
          Iniciar nuevamente
        </Button>
        <Button onClick={onResume} variant="contained">
          Resumir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
