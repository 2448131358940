import { Grid, Box } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  sideInfo?: React.ReactNode;
}

export default function SideInfoContainer({ children, sideInfo }: PropsWithChildren<Props>): JSX.Element {
  return (
    <Grid container height="100vh">
      <Grid
        item
        xs={false}
        sm={4}
        md={5}
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.light,
          [theme.breakpoints.down("sm")]: { display: "none" },
        })}
        component="aside"
      >
        <Box
          sx={{
            py: 8,
            px: "48px",
            color: "#ffff",
            height: "100%",
          }}
        >
          {sideInfo}
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} md={7} component="section">
        <Box
          sx={{
            p: 8,
            height: "100%",
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}
