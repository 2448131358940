import { Link as RouterLink } from "react-router-dom";
import { ProductSummary } from "Pages/Products/styled";
import { LoanData } from "Services/api/finance/interfaces";
import { useIsSmall } from "Hooks/useIsSmall";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import { Accordion, Typography, AccordionDetails, Card, CardContent, Stack, CardActions, Button } from "@mui/material";
import ValueElement from "Shared/ValueElement/ValueElement";

export function LoanSummary({ data }: { data: LoanData }): JSX.Element | null {
  const isSmall = useIsSmall();
  const responsiveDirection = isSmall ? "column" : "row";

  if (data.items.length === 0) return null;

  return (
    <Accordion defaultExpanded variant="outlined" sx={{ backgroundColor: "#F6F9F2", border: "none" }}>
      <ProductSummary>
        <Typography component="h2" sx={{ fontWeight: "bold" }}>
          Préstamos
        </Typography>
      </ProductSummary>
      <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {data.items
          .map((loan) =>
            loan.loansGeneralInfo.map((loansGeneralInfo) =>
              loansGeneralInfo.productTypeDetails.map((productTypeDetails) =>
                (productTypeDetails.productDetails || []).map((productDetails) => (
                  <Card key={productDetails.loanNumber}>
                    <CardContent>
                      <Stack direction={responsiveDirection} flexWrap="wrap" justifyContent={"space-between"}>
                        <ValueElement title={"Número de producto"} value={productDetails.loanNumber} />
                        <ValueElement
                          title={"Tipo de producto"}
                          value={
                            <Typography sx={{ textTransform: "capitalize" }}>
                              {loansGeneralInfo.productClassification.toLowerCase()}
                            </Typography>
                          }
                        />

                        <Stack {...(isSmall ? {} : { spacing: 2 })}>
                          <Stack flexWrap="wrap" direction={"row"} gap="5px 15px" justifyContent={"space-between"}>
                            <Typography>
                              <strong>Balance:</strong>
                            </Typography>
                            <Typography>
                              <MoneyFormat amount={productDetails.loanOutstandingBalance} />
                            </Typography>
                          </Stack>
                          <Stack flexWrap="wrap" direction={"row"} gap="5px 15px" justifyContent={"space-between"}>
                            <Typography>
                              <strong>Cuota mensual:</strong>
                            </Typography>
                            <Typography>
                              <MoneyFormat amount={productDetails.nextAmountDue} />
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Button component={RouterLink} to={`/prestamo/${productDetails.loanNumber}`}>
                        Ver detalle de producto
                      </Button>
                    </CardActions>
                  </Card>
                ))
              )
            )
          )
          .flat()}
      </AccordionDetails>
    </Accordion>
  );
}
