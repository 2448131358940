import { Decoder, object, string, boolean, DecoderType } from "decoders";
import { JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";

export interface OtpValidationData {
  to: string;
  code: string;
}

const OtpChannelValidationDecoder = object({
  channel: string,
  status: string,
  valid: boolean,
});

export type OtpChannelValidationResponse = JsendSuccess<DecoderType<typeof OtpChannelValidationDecoder>>;

export const otpChannelValidationResponseDecoder: Decoder<OtpChannelValidationResponse> =
  jsendSuccessDecoder(OtpChannelValidationDecoder);
