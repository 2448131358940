import { Formik, FormikHelpers } from "formik";
import OtpValidationForm from "Pages/Registration/OtpValidation/OtpValidationForm";
import { useCallback, useState } from "react";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { EditContentProps } from "./interfaces";
import * as Yup from "yup";
import { phoneValidation } from "Utils/validations";
import { useSnackbar } from "notistack";
import { Stack, InputAdornment } from "@mui/material";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { updatePhone } from "Services/api/profile/profile";
import { validatePhone } from "Services/api/otp/otp";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";
import { useHandleError } from "Hooks/useHandleError";
import ProfileToken, { checkToken } from "./ProfileToken";

const schema = Yup.object({
  tel: phoneValidation,
});

interface Payload {
  phone: string;
  code: string;
}

export function PhoneChange({ profile, setProfile, closeDialog }: EditContentProps) {
  const { phone } = profile;
  const [step, setStep] = useState<"edit" | "otp" | "verification">("edit");
  const { enqueueSnackbar } = useSnackbar();
  const [payload, setPayload] = useState<Payload>({ phone, code: "" });
  const handleError = useHandleError();

  const changePhone = useCallback(async (values: Payload) => {
    await checkToken(
      async (token) => {
        const phone = getFullPhoneFormat(values.phone);
        const { status, data } = await updatePhone({
          token,
          phone,
          code: values.code,
        });

        if (status === "success") {
          setProfile({ ...profile, phone });
          enqueueSnackbar(data, { variant: "success" });
          closeDialog();
        } else enqueueSnackbar(data, { variant: "error" });
      },
      () => {
        setPayload(values);
        setStep("verification");
      }
    );
  }, []);

  return step === "edit" ? (
    <Formik
      initialValues={{ tel: phone.replace("+1", "") }}
      validationSchema={schema}
      onSubmit={async ({ tel }) => {
        await validatePhone(getFullPhoneFormat(tel));
        setPayload({ phone: tel, code: "" });
        setStep("otp");
      }}
    >
      <FormikForm width="100%">
        <Stack spacing={2}>
          <FormikTextField
            name="tel"
            id="tel"
            type="tel"
            fullWidth
            label="Celular:"
            variant="outlined"
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">+1</InputAdornment>,
            }}
          />

          <FormikSubmitButton fullWidth variant="contained">
            Continuar
          </FormikSubmitButton>
        </Stack>
      </FormikForm>
    </Formik>
  ) : step === "otp" ? (
    <OtpValidationForm
      channel="sms"
      channelDisplay="teléfono movil"
      to={payload.phone}
      expiryTime={600}
      onSubmit={onPhoneValidationSubmit}
    />
  ) : (
    <ProfileToken
      secretQuestions={profile.securityQuestions}
      onSubmit={async () => {
        await changePhone(payload);
      }}
    />
  );

  async function onPhoneValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      await changePhone({ phone: payload.phone, code });
    } catch (error) {
      handleError(error);
    }

    setSubmitting(false);
  }
}
