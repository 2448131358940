import { Search } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useAsync } from "Hooks/useAsync";
import { useHandleError } from "Hooks/useHandleError";
import { useIsSmall } from "Hooks/useIsSmall";
import { useSnackbar } from "notistack";
import { Dispatch, Fragment, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { getSavingsAccountDetails, getSavingsAccountTransactions } from "Services/api/finance/finance";
import { SavingAccountDetails } from "Services/api/finance/interfaces";
import Contact from "Shared/Contact/Contact";
import { Loading } from "Shared/Loading/Loading";
import { MoneyFormat } from "Shared/MoneyFormat/MoneyFormat";
import ValueElement from "Shared/ValueElement/ValueElement";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
});

interface Page {
  count: number;
  rowsPerPage: number;
  current: number;
  transactions: SavingAccountDetails["transactions"];
}

export function SavingDetails(): JSX.Element {
  const isSmall = useIsSmall();
  const { number } = useParams();
  /* istanbul ignore next */
  const responsiveDirection = isSmall ? "column" : "row";

  const { enqueueSnackbar } = useSnackbar();

  const [details, setDetails] = useState<SavingAccountDetails["details"] | undefined>();
  const [transactions, setTransactions] = useState<SavingAccountDetails["transactions"]>([]);
  const [page, setPage] = useState<{
    count: number;
    rowsPerPage: number;
    current: number;
    transactions: SavingAccountDetails["transactions"];
  }>({
    count: 0,
    rowsPerPage: 0,
    current: 0,
    transactions: [],
  });

  const getInitData = useCallback(async () => {
    if (number) {
      const { status, data } = await getSavingsAccountDetails(number);
      if (status === "success") {
        const { details, transactions } = data;
        setDetails(details);
        transactions.reverse();
        setTransactions(transactions);
        setPage({
          count: transactions.length,
          rowsPerPage: 5,
          current: 0,
          transactions: transactions.slice(0, 5),
        });
      }
    }
  }, [number]);
  const { status } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    console.error(error);
  });

  return (
    <Stack direction={responsiveDirection} spacing={5}>
      <Stack flexBasis={"70%"} width="100%" spacing={1}>
        {status === "pending" || status === "idle" ? (
          <Loading />
        ) : status === "success" && details ? (
          <Fragment>
            <AppBar position="static" sx={{ boxShadow: "none", borderRadius: "4px" }}>
              <Toolbar>
                <Typography component="h2">
                  <strong>Detalles</strong>
                </Typography>
              </Toolbar>
            </AppBar>
            <Stack
              spacing={2}
              component={Card}
              style={{ padding: "30px", backgroundColor: "#F6F9F2" }}
              elevation={0}
              alignItems="center"
              alignContent="center"
              justifyContent="center"
            >
              <Typography sx={{ color: "#315500", fontWeight: "bold" }}>
                Balance Cuenta de Ahorro: {details.accountNumber}
              </Typography>
              <Typography sx={{ color: "#315500", fontSize: "2rem", fontWeight: "bold" }}>
                <MoneyFormat amount={details.availableAccountBalance} />
              </Typography>
            </Stack>

            <Stack spacing={1} component={Card} style={{ backgroundColor: "#F6F9F2" }} elevation={0}>
              <AppBar position="static" sx={{ boxShadow: "none" }}>
                <Toolbar>
                  <Typography component="h2">
                    <strong>Detalles del producto</strong>
                  </Typography>
                </Toolbar>
              </AppBar>

              <Grid container spacing={2} padding="0 15px 15px 15px">
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <ValueElement title={"Número de producto"} value={details.accountNumber} />
                  <ValueElement title={"Tipo de producto"} value={details.accountType} />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <ValueElement title={"Balance"} value={<MoneyFormat amount={details.accountBalance} />} />
                  <ValueElement
                    title={"Balance disponible"}
                    value={<MoneyFormat amount={details.availableAccountBalance} />}
                  />
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <ValueElement title={"Fecha de apertura"} value={details.creationDate} />
                  <ValueElement title={"Moneda"} value={details.currency} />
                </Grid>
              </Grid>
            </Stack>

            <Transactions {...{ transactions, setTransactions, page, setPage }} />
          </Fragment>
        ) : status === "error" ? (
          <div>{GENERIC_ERROR_MESSAGE}</div>
        ) : (
          <></>
        )}
      </Stack>
      <Stack flexBasis={"30%"} width="100%">
        <Contact />
      </Stack>
    </Stack>
  );
}

interface TransactionsProps {
  transactions: SavingAccountDetails["transactions"];
  setTransactions: Dispatch<SavingAccountDetails["transactions"]>;
  page: Page;
  setPage: Dispatch<Page>;
}

function Transactions(props: TransactionsProps) {
  const { transactions, setTransactions, page, setPage } = props;

  const theme = useTheme();
  const isSmall = useIsSmall();
  const { number } = useParams();
  /* istanbul ignore next */
  const responsiveDirection = isSmall ? "column" : "row";
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const handleError = useHandleError();

  const getTransactions = useCallback(async () => {
    if (number) {
      const { status, data } = await getSavingsAccountTransactions(number, from, to);
      if (status === "success") {
        const transactions = data;
        transactions.reverse();
        setTransactions(transactions);
        setPage({
          ...page,
          count: transactions.length,
          transactions: transactions.slice(0, 5),
        });
      }
    }
  }, [number, from, to]);
  const { status, execute } = useAsync(getTransactions, null, handleError, false);

  return (
    <Stack spacing={2} component={Card} style={{ backgroundColor: "#F6F9F2" }} elevation={0}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Stack direction={responsiveDirection} width="100%" alignItems={"center"} gap="10px 5px">
            <Stack flexGrow={1} spacing={1}>
              <Typography component="h2">
                <strong>Movimientos</strong>
              </Typography>
            </Stack>
            <Stack
              flexGrow={0}
              direction={responsiveDirection}
              gap="20px 5px"
              alignItems={"center"}
              sx={{ py: "15px" }}
            >
              <Stack direction={"row"} alignItems={"center"} gap="5px">
                <Typography>Desde:</Typography>
                <TextField
                  name="from"
                  id="from"
                  fullWidth
                  variant="standard"
                  type="date"
                  placeholder="desde:"
                  inputProps={{ sx: { backgroundColor: "#fff", padding: "5px", borderRadius: "3px" } }}
                  value={from}
                  onChange={(ev) => setFrom(ev.target.value)}
                />
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap="5px">
                <Typography>Hasta:</Typography>
                <TextField
                  name="to"
                  id="to"
                  fullWidth
                  variant="standard"
                  type="date"
                  inputProps={{ sx: { backgroundColor: "#fff", padding: "5px", borderRadius: "3px" } }}
                  value={to}
                  onChange={(ev) => setTo(ev.target.value)}
                />
              </Stack>
              <IconButton
                data-testid="search"
                className="logout-button"
                sx={{ backgroundColor: "#fff", width: "fit-content" }}
                onClick={() => void execute()}
              >
                <Search htmlColor={theme.palette.primary.main} />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {status === "pending" ? (
        <Stack spacing={2} alignItems="center">
          <CircularProgress color="inherit" />
          <div>Cargando movimientos...</div>
        </Stack>
      ) : status === "success" || status === "idle" ? (
        transactions.length > 0 ? (
          <TransactionsTable {...{ transactions, page, setPage }} />
        ) : (
          <div>No se encontraron transacciones.</div>
        )
      ) : status === "error" ? (
        <div>{GENERIC_ERROR_MESSAGE}</div>
      ) : (
        <></>
      )}
    </Stack>
  );
}

interface TransactionsTableProps {
  transactions: SavingAccountDetails["transactions"];
  page: Page;
  setPage: Dispatch<Page>;
}

function TransactionsTable(props: TransactionsTableProps) {
  const isSmall = useIsSmall();
  const { transactions, page, setPage } = props;
  return (
    <Box style={{ border: "1px solid rgba(224,224,224,1)", margin: 0 }}>
      {isSmall ? (
        /* istanbul ignore next */ <Stack direction="column">
          {page.transactions.map((item) => {
            const isCredit = Boolean(Number(item.credit));
            return (
              <Stack component={Card} sx={{ m: "10px", p: "10px" }} spacing={1} key={item.transactionReference}>
                <ValueElement title={"Numero:"} value={item.transactionReference} />
                <ValueElement title={"Fecha:"} value={item.transactionDate} />
                <ValueElement title={"Concepto:"} value={item.transactionConcept} />
                <ValueElement
                  title={"Monto:"}
                  value={
                    <Fragment>
                      {isCredit ? "" : "- "}
                      <MoneyFormat amount={isCredit ? item.credit : item.debit} />
                    </Fragment>
                  }
                />
                <ValueElement title={"Balance:"} value={<MoneyFormat amount={item.endingBalance} />} />
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <Table style={{ marginTop: 0 }} aria-label="tabla de movimientos">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#fff" }}>
              <TableCell>Numero</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Concepto</TableCell>
              <TableCell align="right">Monto</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.transactions.map((item) => {
              const isCredit = Boolean(Number(item.credit));
              return (
                <StyledTableRow key={item.transactionReference}>
                  <TableCell>{item.transactionReference}</TableCell>
                  <TableCell>{item.transactionDate}</TableCell>
                  <TableCell>{item.transactionConcept}</TableCell>
                  <TableCell align="right">
                    {isCredit ? "" : "- "}
                    <MoneyFormat amount={isCredit ? item.credit : item.debit} />
                  </TableCell>
                  <TableCell align="right">
                    <MoneyFormat amount={item.endingBalance} />
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={page.count}
        rowsPerPage={page.rowsPerPage}
        page={page.current}
        onPageChange={(_: unknown, newPage: number) => {
          const initTrans = newPage * page.rowsPerPage;
          setPage({
            ...page,
            current: newPage,
            transactions: transactions.slice(initTrans, initTrans + page.rowsPerPage),
          });
        }}
        onRowsPerPageChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          const rowsPerPage = Number(value);
          setPage({
            ...page,
            current: 0,
            rowsPerPage: rowsPerPage,
            transactions: transactions.slice(0, rowsPerPage),
          });
        }}
      />
    </Box>
  );
}
