import { Button, FormHelperText, Stack, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import FileIcon from "Icons/FileIcon";
import { useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

interface FormikImageFieldProps {
  name: string;
  label: string;
}

export function FormikImageField(props: FormikImageFieldProps): JSX.Element {
  const { name, label } = props;
  const [{ value }, , { setValue }] = useField<File | undefined>(props);
  const { isSubmitting, errors: formikErrors, touched } = useFormikContext<{ [k: string]: string }>();
  const formikError = Boolean(touched[props.name]) && Boolean(formikErrors[props.name]);
  const [images, setImages] = useState<ImageListType>([]);

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
    setValue(imageList[0].file);
  };
  return (
    <ImageUploading
      value={images}
      onChange={onImageChange}
      acceptType={["jpg", "jpeg", "png", "pdf"]}
      maxFileSize={6291456}
      dataURLKey="data_url"
      allowNonImageType
    >
      {({ onImageUpload, errors }) => (
        <Stack direction="row" width="100%" spacing={2} alignItems="center" flexWrap="wrap">
          <Stack>
            <Button variant="outlined" startIcon={<FileIcon />} disabled={isSubmitting} onClick={onImageUpload}>
              {value ? `Cambiar archivo de ${label}` : `Adjuntar archivo de ${label}`}
            </Button>
            {value ? <FormHelperText> {value.name}</FormHelperText> : null}
            {errors?.maxFileSize ? <FormHelperText error>El archivo no puede ser mayor de 6 MB</FormHelperText> : null}
            {formikError ? <FormHelperText error> {formikErrors[name]}</FormHelperText> : null}
          </Stack>

          <Typography>
            El archivo debe estar en formato jpg, png o pdf, y pesar menos de 6 MB. 1 solo archivo.
          </Typography>
        </Stack>
      )}
    </ImageUploading>
  );
}
