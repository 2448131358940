import { Formik, FormikHelpers } from "formik";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikForm from "Shared/FormikForm/FormikForm";
import * as Yup from "yup";
import { Button, DialogTitle, DialogContent, DialogActions, Dialog, Link, styled, Typography } from "@mui/material";
import { useTimer } from "react-timer-hook";
import FormikOtpCodeField from "./FormikOtpCodeField";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { validatePhone, validateEmail } from "Services/api/otp/otp";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";

const VerificationTitle = styled(Typography)(() => ({
  color: "#7C7C7C",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "55px",
}));

const Text = styled(Typography)(() => ({
  color: "#7C7C7C",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
}));

const otpValidationSchema = Yup.object({
  code: Yup.string().required("Codigo Requerido").length(6, "Codigo incorrecto"),
});

interface Props {
  channel: "sms" | "email";
  channelDisplay: string;
  to: string;
  expiryTime: number;
  onSubmit: (values: { code: string }, { setSubmitting }: FormikHelpers<{ code: string }>) => Promise<void>;
}

export default function OtpValidationForm({ channel, channelDisplay, to, expiryTime, onSubmit }: Props): JSX.Element {
  const textButtonStyle = { justifyContent: "left", padding: "6px 0px;", width: "fit-content" };
  const { enqueueSnackbar } = useSnackbar();

  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + expiryTime);
  const { seconds, minutes, restart } = useTimer({ expiryTimestamp });

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Formik initialValues={{ code: "" }} validationSchema={otpValidationSchema} onSubmit={onSubmit}>
      <FormikForm width="100%">
        <Text>
          Hemos enviado un código OTP a su {channelDisplay} <strong>{to}</strong>:
        </Text>
        <VerificationTitle variant="h2">Insertar código</VerificationTitle>
        <FormikOtpCodeField name="code" id="code" fullWidth variant="outlined" required />
        <Text>
          Este código estará vigente los próximos{" "}
          {`${minutes}:${seconds.toString().length === 2 ? seconds : `0${seconds}`}`} minutos
        </Text>

        <Button sx={textButtonStyle} onClick={() => void resendCode()}>
          Volver a enviar código
        </Button>
        <Button sx={textButtonStyle} onClick={() => void setOpenDialog(true)}>
          No he recibido el código
        </Button>
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
        {openDialog ? <NoCodeDialog open={openDialog} channel={channel} onClose={() => setOpenDialog(false)} /> : null}
      </FormikForm>
    </Formik>
  );

  async function resendCode() {
    try {
      if (channel === "sms") await validatePhone(getFullPhoneFormat(to));
      else await validateEmail(to);
      enqueueSnackbar("Codigo reenviado");
      const expiryTimestamp = new Date();
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + expiryTime);
      restart(expiryTimestamp);
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
  }
}

interface NoCodeDialogProps {
  open: boolean;
  channel: Props["channel"];
  onClose: () => void;
}

function NoCodeDialog(props: NoCodeDialogProps) {
  const { onClose, channel, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>¿No has recibido el código?</DialogTitle>
      <DialogContent>
        <p>Si no te llega el código puedes:</p>
        <ul>
          <li>
            <strong>Esperar unos minutos:</strong> A veces puede tomar algunos minutos para el código llegar a tu{" "}
            {channel === "sms" ? "dispositivo" : "correo"}.
          </li>
          {channel === "email" ? (
            <li>
              <strong>Revisar spam:</strong> Puedes intentar revisar tu correo no deseado (spam).
            </li>
          ) : null}
          <li>
            <strong>Contactarnos:</strong> Si aún no recibes el código puedes escribirnos en{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              nuestra pagina de contacto
            </Link>{" "}
            y con gusto te ayudaremos.
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}
