import { Formik, FormikHelpers } from "formik";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikForm from "Shared/FormikForm/FormikForm";
import OtpValidationForm from "../OtpValidation/OtpValidationForm";
import OtpValidation, { InputFormProps, ValidationFormProps } from "../OtpValidation/OtpValidation";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useRegistrationContext } from "../Registration";
import { validatePhone } from "Services/api/otp/otp";
import { OtpValidationData } from "Services/api/otp/interfaces";
import { confirmPhone, savePhone } from "Services/api/register/register";
import { Typography, Link, FormHelperText, InputAdornment } from "@mui/material";
import { useState } from "react";
import { getFullPhoneFormat } from "Utils/getFullPhoneFormat";
import { phoneValidation } from "Utils/validations";

export default function CellphoneValidation(): JSX.Element {
  return (
    <OtpValidation
      title="Verificación de número de contacto"
      InputForm={CellphoneInputForm}
      ValidationForm={CellphoneValidationForm}
    />
  );
}
const phoneInputSchema = Yup.object({
  tel: phoneValidation,
});

function CellphoneInputForm({ setStatus, setTo }: InputFormProps): JSX.Element {
  const [{ id, isAffiliatedClient, partialNumber }] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();
  const [phoneError, setPhoneError] = useState("");

  return (
    <Formik initialValues={{ tel: "" }} validationSchema={phoneInputSchema} onSubmit={onCellphoneInputSubmit}>
      <FormikForm width="100%">
        {isAffiliatedClient ? (
          <Typography>
            Introduzca el telefono <strong>{partialNumber}</strong> para confirmar que todavía se le puede contactar por
            esta via. Si no reconoce o no recuerda este numero puede{" "}
            <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
              <strong>hacer click aqui para contactarnos.</strong>
            </Link>
          </Typography>
        ) : null}
        <FormikTextField
          name="tel"
          id="tel"
          type="tel"
          fullWidth
          label="Celular:"
          variant="outlined"
          required
          InputProps={{
            startAdornment: <InputAdornment position="start">+1</InputAdornment>,
          }}
        />
        {phoneError ? <FormHelperText error>{phoneError}</FormHelperText> : null}
        <FormikSubmitButton fullWidth variant="contained">
          Siguiente
        </FormikSubmitButton>
      </FormikForm>
    </Formik>
  );

  async function onCellphoneInputSubmit(
    { tel }: { tel: OtpValidationData["to"] },
    { setSubmitting }: FormikHelpers<{ tel: OtpValidationData["to"] }>
  ) {
    setPhoneError("");
    try {
      if (isAffiliatedClient) {
        const { status, data } = await confirmPhone(id, getFullPhoneFormat(tel));

        if (status === "success") {
          setStatus("validation");
          setTo(tel);
          enqueueSnackbar(data, { variant: "success" });
        } else {
          setPhoneError(data);
          enqueueSnackbar(data, { variant: "error" });
        }
      } else {
        await validatePhone(getFullPhoneFormat(tel));
        setStatus("validation");
        setTo(tel);
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }

    setSubmitting(false);
  }
}

function CellphoneValidationForm({ to }: ValidationFormProps): JSX.Element {
  const [{ id }, setRegContext] = useRegistrationContext();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <OtpValidationForm
      channel="sms"
      channelDisplay="teléfono movil"
      to={to}
      expiryTime={600}
      onSubmit={onCellphoneValidationSubmit}
    />
  );

  async function onCellphoneValidationSubmit(
    { code }: { code: OtpValidationData["code"] },
    { setSubmitting }: FormikHelpers<{ code: string }>
  ) {
    try {
      const response = await savePhone({
        id,
        to: getFullPhoneFormat(to),
        code,
      });

      if (response.status === "success") {
        enqueueSnackbar("Teléfono movil validado", { variant: "success" });
        setRegContext({ status: response.data.status });
      } else enqueueSnackbar(response.data, { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  }
}
