import { Box, Stack, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { FormikValues } from "formik";
import { useAsync } from "Hooks/useAsync";
import NavBar from "Layout/NavBar/NavBar";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { ClientFormData, ClientFormOptions, RegistrationModel } from "Services/api/register/interfaces";
import {
  getClientData,
  getClientFormOptions,
  saveAddressData,
  saveFinanceData,
  savePersonalData,
  saveWorkData,
} from "Services/api/register/register";
import { AddressForm } from "Shared/AddressForm/AddressForm";
import { Loading } from "Shared/Loading/Loading";
import { SectionTitle } from "Shared/styled";
import { useRegistrationContext } from "../Registration";
import { FinanceForm } from "./FinanceForm";
import { StepFormProps } from "./interfaces";
import { PersonalForm } from "./PersonalForm";
import { Connector, StepIcon } from "./styled";
import { WorkForm } from "./WorkForm";

const steps = ["Datos del socio", "Datos de residencia", "Datos laborales", "Datos financieros"];

const Forms: [keyof ClientFormData, <T extends FormikValues>(props: StepFormProps<T>) => JSX.Element][] = [
  ["personal", PersonalForm],
  ["address", AddressForm],
  ["work", WorkForm],
  ["finance", FinanceForm],
];

const initialValues: ClientFormData = {
  personal: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    nationality: "",
    profession: "",
  },
  address: {
    country: "República Dominicana",
    region: "",
    province: "",
    city: "",
    sector: "",
    line1: "",
    line2: "",
    addressCert: undefined,
  },
  work: {
    jobStatus: "",
    salary: null,
    dateOfRetirement: "",
    dateOfHire: "",
    jobType: "",
    paymentType: "",
    sessionType: "",
    position: "",
    department: "",
    rnc: "",
    institutionName: "",
    institutionEmail: "",
    institutionPhone: "",
    institutionAddress: "",
    economicSector: "",
    commercialActivity: "",
    proofOfIncome: undefined,
  },
  finance: {
    affiliationPurpose: {
      financing: false,
      savings: false,
      certificate: false,
    },
    savingAmount: null,
    inputAmount: 0,
    minInitialDeposit: 0,
    initialDeposit: null,
    typeOfPayment: "",
    numberOfPayments: "",
    typeOfInstallments: "",
    terms: false,
  },
};

const initialOptions: ClientFormOptions = {
  nationalities: [],
  professions: [],
  regions: [],
  jobStatus: [],
  jobTypes: [],
  paymentTypes: [],
  sessionTypes: [],
  positions: [],
  departments: [],
  economicSectors: [],
  typesOfPayment: [],
  typesOfInstallment: [],
  numberOfMonthlyPayments: [],
  numberOfBiweeklyPayments: [],
};

export default function ClientForm(): JSX.Element {
  const [formOptions, setFormOptions] = useState(initialOptions);
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [{ id }, setRegContext] = useRegistrationContext();
  const [formData, setFormData] = useState(initialValues);

  const getInitData = useCallback(async () => {
    const { data, options } = await getFormData(id);
    setFormData(data);
    setFormOptions(options);
  }, [id]);
  const { status } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
    console.error(error);
  });

  const FormElement = Forms[activeStep][1];
  const onBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  return (
    <NavBar>
      <Box sx={{ width: "100%", background: "#87A544", pt: 10, pb: "15px" }}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
          {steps.map((label, index) => (
            <Step sx={{ display: "flex", justifyContent: "center" }} key={label}>
              <StepButton onClick={() => setActiveStep(index)} sx={{ width: "fit-content" }}>
                <StepLabel StepIconComponent={StepIcon} componentsProps={{ label: { style: { color: "#fff" } } }}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      {status === "pending" || status === "idle" ? (
        <Loading />
      ) : status === "success" ? (
        <Stack justifyContent="center" alignItems="start" sx={{ px: "48px", pt: "25px" }}>
          <SectionTitle variant="h1">{steps[activeStep]}</SectionTitle>
          <FormElement
            options={formOptions}
            initialValues={formData[Forms[activeStep][0]]}
            onSubmit={(values, form) => void onFormElementSubmit(values, form)}
            onBack={onBack}
          />
        </Stack>
      ) : status === "error" ? (
        <div>Ha ocurrido un error obteniendo los datos del formulario...</div>
      ) : (
        <></>
      )}
    </NavBar>
  );

  async function onFormElementSubmit(
    values: ClientFormData["personal"] | ClientFormData["address"] | ClientFormData["work"] | ClientFormData["finance"],
    form: keyof ClientFormData
  ) {
    try {
      if (form === "personal") {
        values = values as ClientFormData["personal"];
        await savePersonalData(id, values);
        setFormData({ ...formData, personal: values });
        setActiveStep(activeStep + 1);
      } else if (form === "address") {
        values = values as ClientFormData["address"];
        const requestData = new FormData();
        requestData.append("id", id);

        for (const key in values) {
          const el = values[key as keyof ClientFormData["address"]];
          if (el) requestData.append(key, el);
        }

        await saveAddressData(requestData);
        setFormData({ ...formData, address: values });
        setActiveStep(activeStep + 1);
      } else if (form === "work") {
        values = values as ClientFormData["work"];
        const requestData = new FormData();
        requestData.append("id", id);

        for (const key in values) {
          const el = values[key as keyof ClientFormData["work"]];
          if (el) requestData.append(key, typeof el === "number" ? el.toString() : el);
        }
        await saveWorkData(requestData);
        setFormData({ ...formData, work: values });
        setActiveStep(activeStep + 1);
      } else {
        values = values as ClientFormData["finance"];
        const {
          data: { status },
        } = await saveFinanceData(id, values);
        setRegContext({ status });
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
  }
}

async function getFormData(id: RegistrationModel["id"]) {
  const { data: options } = await getClientFormOptions();
  const { data } = await getClientData(id);
  return {
    data,
    options,
  };
}
