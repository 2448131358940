import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useHasCamera } from "Hooks/useHasCamera";
import { useSingleEffect } from "Hooks/useSingleEffect";
import SplitFormContainer from "Layout/SplitFormContainer/SplitFormContainer";
import { ProviderContext, useSnackbar } from "notistack";
import { Text } from "Pages/Registration/IdentityVerification/styled";
import { Dispatch, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { MetamapVerification, MetamapVerificationResponse } from "Services/api/metamap/interfaces";
import {
  createIdentityVerification,
  getVerificationStatus,
  submitDocuments,
  submitVideos,
} from "Services/api/metamap/metamap";
import { RegistrationModel } from "Services/api/register/interfaces";
import { saveMetamapInfo } from "Services/api/register/register";
import AccessInfo from "Shared/AccessInfo/AccessInfo";
import AccessRequirements from "Shared/AccessRequirements/AccessRequirements";
import QRCode from "Shared/QRCode/QRCode";
import { useRegistrationContext } from "../Registration";
import BiometricVideo from "./BiometricVideo";
import IdForm from "./IdForm";
import Instructions from "./Instructions";
import { FormProps } from "./interfaces";

type FormViewTuple = [string, (props: FormProps) => JSX.Element];

const FormView: {
  instructions: FormViewTuple;
  id: FormViewTuple;
  video: FormViewTuple;
} = {
  instructions: ["Verificación de identidad", Instructions],
  id: ["Verificación de cédula", IdForm],
  video: ["Verificación biométrica", BiometricVideo],
};

type Status = "instructions" | "id" | "video";

interface BackdropState {
  open: boolean;
  msg: string | React.ReactNode;
}

export default function IdentityVerification(): JSX.Element {
  const [status, setStatus] = useState<Status>("instructions");
  const [verification, setVerification] = useState<MetamapVerificationResponse["data"]>({ id: "", identity: "" });
  const { enqueueSnackbar } = useSnackbar();
  const [{ id }, setRegContext] = useRegistrationContext();
  const [backdropState, setBackdropState] = useState<BackdropState>({ open: false, msg: "" });
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [hasCamara, setHasCamera] = useHasCamera();

  const [title, FormElement] = FormView[status];

  useSingleEffect(() => {
    setHasCamera();
  });

  return (
    <SplitFormContainer
      title={title}
      sideInfoTop={<AccessInfo />}
      sideInfoBottom={<AccessRequirements />}
      form={
        <>
          {hasCamara == false ? (
            <InstructionsQRCode id={id} />
          ) : (
            <FormElement
              onSubmit={
                {
                  instructions: onSubmitInstructions(setVerification, setStatus, enqueueSnackbar),
                  id: onSubmitId(setBackdropState, verification, setVerification, setStatus, enqueueSnackbar),
                  video: onSubmitVideo(
                    id,
                    setBackdropState,
                    verification,
                    setRegContext,
                    enqueueSnackbar,
                    setOpenErrorDialog,
                    setStatus
                  ),
                }[status]
              }
            />
          )}
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={backdropState.open}>
            <Stack spacing={2} alignItems="center">
              <CircularProgress color="inherit" />
              <div>{backdropState.msg}</div>
            </Stack>
          </Backdrop>
          <ValidationErrorDialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)} />
        </>
      }
    />
  );
}

interface ValidationErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

const ValidationErrorDialog = (props: ValidationErrorDialogProps) => {
  const { onClose, open } = props;
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle color={"darkred"}>Error de validación de identidad</DialogTitle>
      <DialogContent>
        <p>
          Al parecer no hemos podido validar tu identidad correctamente, puedes volver a intentarlo asegurandote que:
        </p>
        <ul>
          <li>La cédula proporcionada tenga la misma numeración que la cédula usada para el registro.</li>
          <li>Tu cédula se vea claramente en ambos lados.</li>
          <li>La persona en el video sea la misma que la persona en la cédula.</li>
        </ul>
        <p>
          Si continuas recibiendo este error puedes ir a{" "}
          <Link target="_blank" rel="noopener" href="https://coopbarcelona.com/contactanos/">
            nuestra pagina de contacto.
          </Link>{" "}
          y con gusto te ayudaremos.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

function onSubmitVideo(
  id: RegistrationModel["id"],
  setBackdropState: Dispatch<BackdropState>,
  verification: MetamapVerification,
  setRegContext: (overrides: Partial<RegistrationModel>) => void,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setOpenErrorDialog: Dispatch<boolean>,
  setStatus: Dispatch<Status>
) {
  return async (data: FormData) => {
    try {
      setBackdropState({ open: true, msg: "Enviando video..." });
      const res = await submitVideos(verification, data);
      if (res.status === "fail") return res.data;

      setBackdropState({
        open: true,
        msg: <ValidationMessage />,
      });
      const valid = await isVerificationValid(verification);

      if (valid) {
        setBackdropState({
          open: true,
          msg: "Espere un momento mientras validamos que las numeraciones de las cédula concuerdan...",
        });

        const { status, data } = await saveMetamapInfo(id, verification);

        if (status === "fail") {
          enqueueSnackbar(data, { variant: "error" });
          setOpenErrorDialog(true);
          setStatus("instructions");
        } else {
          setRegContext({ status: data.status });
          enqueueSnackbar("Identidad validada existosamente", { variant: "success" });
        }
      } else {
        enqueueSnackbar("Ha ocurrido un error en la validación de identidad", { variant: "error" });
        setOpenErrorDialog(true);
        setStatus("instructions");
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
      setStatus("instructions");
    } finally {
      setBackdropState({ open: false, msg: "" });
    }
  };
}

function onSubmitId(
  setBackdropState: Dispatch<BackdropState>,
  verification: { id: string; identity: string },
  setVerification: Dispatch<{ id: string; identity: string }>,
  setStatus: Dispatch<Status>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  return async (data: FormData) => {
    try {
      setBackdropState({ open: true, msg: "Enviando documentos..." });
      const { status, data: resData } = await submitDocuments(verification, data);

      if (status === "fail") {
        // reset identity verification in case one input is locked from a partial success
        const { front, back } = resData;
        if (!("errorKey" in front) || !("errorKey" in back)) {
          const { data } = await createIdentityVerification();
          setVerification(data);
        }

        return resData;
      }

      setStatus("video");
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
      setStatus("instructions");
    } finally {
      setBackdropState({ open: false, msg: "" });
    }
  };
}

function onSubmitInstructions(
  setVerification: Dispatch<{ id: string; identity: string }>,
  setStatus: Dispatch<"video" | "id" | "instructions">,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  return async () => {
    try {
      const { data } = await createIdentityVerification();
      setVerification(data);
      setStatus("id");
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
  };
}

export function isVerificationValid(verification: MetamapVerificationResponse["data"]): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const tryLimit = 20;
    let tries = 0;
    const intervalId = setInterval(() => void loop(), 15000); // 4 tries per minute

    void loop();

    async function loop() {
      try {
        tries++;
        const res = await getVerificationStatus(verification);

        if (res.status === "fail") {
          // Stop if error detected
          clearInterval(intervalId);
          return resolve(false);
        } else {
          if (res.data.valid) {
            clearInterval(intervalId);
            return resolve(true);
          }

          // Assume fail if 5 minutes has passed
          if (tries >= tryLimit) {
            clearInterval(intervalId);
            return resolve(false);
          }
        }
      } catch (error) {
        clearInterval(intervalId);
        return reject(error);
      }
    }
  });
}

function ValidationMessage() {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 300);
  const { minutes, seconds } = useTimer({ expiryTimestamp });

  return (
    <Stack spacing={2} alignItems="center">
      <strong>{`${minutes}:${seconds.toString().length === 2 ? seconds : `0${seconds}`}`}</strong>
      <Typography>Espere un momento mientras validamos tu identidad...</Typography>
    </Stack>
  );
}

function InstructionsQRCode({ id }: { id: string }) {
  const path = createSearchParams({
    id,
  });
  const url = `${location.href}?${path.toString()}`;
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Text>
        Para proceeder en el proceso de registro debes tener una cámara disponible de forma que podamos confirmar tu
        identidad. Este dispositivo no posee dicha funcionalidad, sin embargo puedes escanear el siguiente código QR y
        continuar el proceso de registro desde tu dispositivo móvil.
      </Text>
      <br />
      <QRCode url={url} />
    </Stack>
  );
}
