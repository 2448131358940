import { Button, Grid, InputAdornment, MenuItem } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues, useField, useFormikContext } from "formik";
import { Fragment } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { ClientFormData, ClientFormOptions } from "Services/api/register/interfaces";
import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { useRegistrationContext } from "../Registration";
import { clientFormSchema, StepFormProps } from "./interfaces";

const Forms = {
  normal: NormalForm,
  salaried: SalariedForm,
  independent: IndependentForm,
};

export function WorkForm<T extends FormikValues>(props: StepFormProps<T>) {
  const { options, onSubmit, initialValues, onBack } = props;
  const [{ type }] = useRegistrationContext();

  const FormFragment = Forms[type];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: T, { setSubmitting }: FormikHelpers<T>) => {
        onSubmit(values, "work");
        setSubmitting(false);
      }}
      validationSchema={clientFormSchema.work(type)}
    >
      <Grid component={Form} container width="100%" spacing={2}>
        <FormFragment options={options} />

        <Grid item xs={6} width="100%">
          <Button fullWidth variant="outlined" onClick={onBack}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} width="100%">
          <FormikSubmitButton fullWidth variant="contained">
            Siguiente
          </FormikSubmitButton>
        </Grid>
      </Grid>
    </Formik>
  );
}

interface WorkFormProps {
  options: ClientFormOptions;
}

function NormalForm({
  options: { jobStatus, jobTypes, paymentTypes, sessionTypes, positions, departments },
}: WorkFormProps) {
  return (
    <Fragment>
      <JobStatus jobStatusOptions={jobStatus} />
      <Grid item sm={6} width="100%">
        <Salary label={"Salario mensual:"} />
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikTextField
          name="dateOfHire"
          id="dateOfHire"
          fullWidth
          label="Fecha de entrada:"
          variant="outlined"
          type="date"
          required
        />
      </Grid>

      <Grid item sm={6} width="100%">
        <FormikSelect id="jobType" name="jobType" label="Tipo de empleo:" required>
          {jobTypes.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect id="paymentType" name="paymentType" label="Forma de pago:" required>
          {paymentTypes.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>

      <Grid item sm={6} width="100%">
        <FormikSelect id="sessionType" name="sessionType" label="Tipo de jornada:" required>
          {sessionTypes.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect name="position" id="position" label="Cargo/posición:" required>
          {positions.map((positionOption) => (
            <MenuItem key={positionOption.unique_id} value={positionOption.name}>
              {positionOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect name="department" id="department" label="Departamento:" required>
          {departments.map((departmentOption) => (
            <MenuItem key={departmentOption.unique_id} value={departmentOption.name}>
              {departmentOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%"></Grid>
    </Fragment>
  );
}

function JobStatus({ jobStatusOptions }: { jobStatusOptions: ClientFormOptions["jobStatus"] }) {
  const {
    values: { jobStatus },
  } = useFormikContext<ClientFormData["work"]>();
  const RETIRED = "retired";
  const isRetired = jobStatus === RETIRED;

  return (
    <Fragment>
      <Grid item sm={isRetired ? 6 : 12} width="100%">
        <FormikSelect id="jobStatus" name="jobStatus" label="Estado laboral:" required>
          {jobStatusOptions.map(([value, display]) => (
            <MenuItem key={value} value={value}>
              {display}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>

      {isRetired && (
        <Grid item sm={6} width="100%">
          <FormikTextField
            name="dateOfRetirement"
            id="dateOfRetirement"
            fullWidth
            label="Fecha de jubilación:"
            variant="outlined"
            type="date"
            required
          />
        </Grid>
      )}
    </Fragment>
  );
}

function Salary({ label }: { label: string }) {
  const [{ value }, , { setValue }] = useField<ClientFormData["work"]["salary"]>("salary");
  const numericConfig = {
    allowNegative: false,
    decimalScale: 2,
    thousandSeparator: ",",
  };
  const { format } = useNumericFormat<typeof FormikTextField>(numericConfig);
  return (
    <NumericFormat
      name="salary"
      id="salary"
      fullWidth
      label={label}
      variant="outlined"
      value={value ? format(value.toString()) : ""}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      customInput={FormikTextField}
      {...numericConfig}
      onValueChange={({ floatValue }) => {
        setValue(floatValue ? floatValue : null);
      }}
    />
  );
}

function SalariedForm({ options: { positions, departments } }: WorkFormProps) {
  return (
    <Fragment>
      <Grid item sm={6} width="100%">
        <Salary label={"Salario mensual:"} />
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect name="position" id="position" label="Cargo/posición:" required>
          {positions.map((positionOption) => (
            <MenuItem key={positionOption.unique_id} value={positionOption.name}>
              {positionOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikSelect name="department" id="department" label="Departamento:" required>
          {departments.map((departmentOption) => (
            <MenuItem key={departmentOption.unique_id} value={departmentOption.name}>
              {departmentOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikTextField
          name="dateOfHire"
          id="dateOfHire"
          fullWidth
          label="Fecha de entrada:"
          variant="outlined"
          type="date"
          required
        />
      </Grid>

      <Grid item sm={6} width="100%">
        <FormikTextField
          name="institutionName"
          id="institutionName"
          label="Nombre de la empresa:"
          variant="outlined"
          required
        />
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikTextField name="rnc" id="rnc" label="RNC:" variant="outlined" required />
      </Grid>

      <Grid item sm={6} width="100%">
        <FormikTextField
          name="institutionEmail"
          id="institutionEmail"
          label="Correo de la empresa:"
          variant="outlined"
          required
        />
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikTextField
          name="institutionPhone"
          id="institutionPhone"
          label="Telefono de la empresa:"
          variant="outlined"
          required
        />
      </Grid>

      <Grid item sm={12} width="100%">
        <FormikTextField
          name="institutionAddress"
          id="institutionAddress"
          fullWidth
          label={"Dirección de la empresa:"}
          variant="outlined"
        />
      </Grid>
    </Fragment>
  );
}

function IndependentForm({ options: { economicSectors } }: WorkFormProps) {
  return (
    <Fragment>
      <Grid item sm={6} width="100%">
        <FormikSelect name="economicSector" id="economicSector" label="Sector Economico:" required>
          {economicSectors.map((economicSectorOption) => (
            <MenuItem key={economicSectorOption.unique_id} value={economicSectorOption.name}>
              {economicSectorOption.name}
            </MenuItem>
          ))}
        </FormikSelect>
      </Grid>
      <Grid item sm={6} width="100%">
        <FormikTextField
          name="commercialActivity"
          id="commercialActivity"
          label="Actividad comercial:"
          variant="outlined"
          required
        />
      </Grid>
      <Grid item sm={12} width="100%">
        <Salary label={"Promedio de ingresos mensual:"} />
      </Grid>
      <Grid item sm={12} width="100%">
        <FormikImageField name="proofOfIncome" label="comprobante de ingresos" />
      </Grid>
    </Fragment>
  );
}
