import { Stack } from "@mui/material";
import NavBar from "../NavBar/NavBar";
import SideInfoContainer from "./SideInfoContainer";
import SideInfo from "./SideInfo";
import { PropsWithChildren } from "react";
import { SectionTitle } from "Shared/styled";

interface Props {
  title: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  sideInfoTop?: React.ReactNode;
  sideInfoBottom?: React.ReactNode;
  form?: React.ReactNode;
}

export default function SplitFormContainer({
  title,
  before,
  after,
  sideInfoTop,
  sideInfoBottom,
  form,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <NavBar>
      <SideInfoContainer sideInfo={<SideInfo top={sideInfoTop} bottom={sideInfoBottom} />}>
        {before}
        <Stack direction="column" justifyContent="center" alignItems="start" height="100%">
          <SectionTitle variant="h1">{title}</SectionTitle>
          {form}
        </Stack>
        {after}
      </SideInfoContainer>
    </NavBar>
  );
}
