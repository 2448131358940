import { ClientFormData, ClientFormOptions, RegistrationModel } from "Services/api/register/interfaces";
import { emailValidation, phoneValidation, requiredNumberValidation, requiredValidation } from "Utils/validations";
import * as Yup from "yup";

export type StepFormProps<T> = {
  options: ClientFormOptions;
  onSubmit: (values: T, form: keyof ClientFormData) => void;
  onBack: () => void;
  initialValues: T;
};

export const clientFormSchema = {
  personal: Yup.object({
    firstName: requiredValidation,
    lastName: requiredValidation,
    dateOfBirth: requiredValidation,
    nationality: requiredValidation,
    profession: requiredValidation,
  }),
  address: Yup.object({
    country: requiredValidation,
    region: requiredValidation,
    province: requiredValidation,
    city: requiredValidation,
    sector: requiredValidation,
    line1: requiredValidation,
    line2: Yup.string(),
    addressCert: Yup.mixed(),
  }),
  work: getWorkSchema,
  finance: Yup.object({
    affiliationPurpose: Yup.object({
      financing: Yup.boolean(),
      savings: Yup.boolean(),
      certificate: Yup.boolean(),
    }),
    savingAmount: requiredNumberValidation,
    inputAmount: requiredNumberValidation
      .integer("Este campo debe ser un numero entero")
      .moreThan(0, "Este campo debe ser mayor que cero"),
    minInitialDeposit: Yup.number(),
    initialDeposit: requiredNumberValidation.min(
      Yup.ref("minInitialDeposit"),
      "El monto es menor del minimo requerido: RD$ ${min}"
    ),
    typeOfPayment: requiredValidation,
    numberOfPayments: Yup.string(),
    typeOfInstallments: Yup.string(),
    terms: Yup.boolean().isTrue("Debe leer y aceptar los terminos y condiciones"),
  }),
};

function getWorkSchema(type: RegistrationModel["type"]) {
  if (type === "normal")
    return Yup.object({
      jobStatus: requiredValidation,
      salary: requiredNumberValidation,
      dateOfRetirement: Yup.string(),
      dateOfHire: requiredValidation,
      jobType: requiredValidation,
      paymentType: requiredValidation,
      sessionType: requiredValidation,
      position: requiredValidation,
    });

  if (type === "salaried")
    return Yup.object({
      salary: requiredNumberValidation,
      position: requiredValidation,
      department: requiredValidation,
      dateOfHire: requiredValidation,
      rnc: requiredValidation.matches(/^[0-9]{1}-?[0-9]{2}-?[0-9]{5}-?[0-9]{1}$/, "RNC invalido"),
      institutionName: requiredValidation,
      institutionEmail: emailValidation,
      institutionPhone: phoneValidation,
      institutionAddress: requiredValidation,
    });

  if (type === "independent")
    return Yup.object({
      economicSector: requiredValidation,
      commercialActivity: requiredValidation,
      salary: requiredNumberValidation,
      proofOfIncome: Yup.mixed().required("Archivo requerido"),
    });

  throw "Unhandled client type";
}
