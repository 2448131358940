import { Stack } from "@mui/material";

interface Props {
  top?: React.ReactNode;
  bottom?: React.ReactNode;
}

export default function SideInfo({ top, bottom }: Props): JSX.Element {
  return (
    <Stack spacing={2} direction="column" justifyContent="center" alignItems="start" height="100%" width="100%">
      {top}
      {bottom}
    </Stack>
  );
}
