import { Card, Grid, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import ForgotPasswordDialog from "Pages/ForgotPassword/ForgotPasswordDialog/ForgotPasswordDialog";
import ClientWithAccessDialog from "Pages/Login/ClientWithAccessDialog";
import { LoginData, loginDataKeys, loginDataSchema, LoginFormData } from "Pages/Login/interfaces";
import { Dispatch, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { getSecretQuestions, login } from "Services/api/auth/auth";
import ClientIdInput from "Shared/ClientIdInput/ClientIdInput";
import FormikForm from "Shared/FormikForm/FormikForm";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import PasswordHide from "Shared/PasswordHide/PasswordHide";

export default function AuthenticateStep({
  model,
  setModel,
}: {
  model: LoginData;
  setModel: Dispatch<LoginData>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(searchParams.has("client"));
  const [forgotPasswordDialog, setDialogForgotPasswordDialog] = useState(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack spacing={6} width="100%">
      <Formik
        initialValues={{ [loginDataKeys[0]]: "", [loginDataKeys[1]]: "" }}
        validationSchema={loginDataSchema}
        onSubmit={onLoginSubmit}
      >
        <FormikForm width="100%">
          <ClientIdInput name={loginDataKeys[0]} id={loginDataKeys[0]} autoComplete="username" />
          <PasswordHide
            name={loginDataKeys[1]}
            id={loginDataKeys[1]}
            fullWidth
            autoComplete="current-password"
            label="Contraseña:"
            variant="outlined"
            required
          />

          <FormikSubmitButton fullWidth variant="contained">
            Acceder
          </FormikSubmitButton>
          <Grid container>
            <Grid item xs>
              <Link sx={{ cursor: "pointer" }} target="_blank" onClick={() => void setDialogForgotPasswordDialog(true)}>
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/registro">
                ¿Aún no eres socio?, ¡Afíliate!
              </Link>
            </Grid>
          </Grid>
          <ClientWithAccessDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            openForgotPassword={() => setDialogForgotPasswordDialog(true)}
          />
          <ForgotPasswordDialog open={forgotPasswordDialog} onClose={() => setDialogForgotPasswordDialog(false)} />
        </FormikForm>
      </Formik>
      {isSmall && (
        <Stack spacing={2} component={Card} padding="15px" elevation={5}>
          <Typography component="p">
            Para poder comenzar el proceso de afiliación debes cumplir con los siguientes requisitos:
          </Typography>
          <ol>
            <li>Nacionalidad dominicana.</li>
            <li>No ser una persona políticamente expuesta (PEP).</li>
            <li>Tener tu código empleador en caso de que tu empresa está afiliada.</li>
            <li>Tener un correo electrónico válido.</li>
            <li>Acceso a un dispositivo con cámara.</li>
            <li>Poseer contigo tu documento de identificación o cedula.</li>
          </ol>
        </Stack>
      )}
    </Stack>
  );

  async function onLoginSubmit(values: LoginFormData, { setSubmitting }: FormikHelpers<LoginFormData>) {
    try {
      const { status, data } = await login({ clientId: values.clientId, password: values.password });
      if (status === "success") {
        const { status: statusQuestions, data: secretQuestions } = await getSecretQuestions(
          {
            uid: data.uid,
            token: data.token,
          },
          "?type=login"
        );
        if (statusQuestions === "success") {
          setModel({
            ...model,
            secretQuestions,
            status: "secret-questions",
            uid: data.uid,
            token: data.token,
          });
          return;
        } else {
          enqueueSnackbar(secretQuestions.message, { variant: "error" });
        }
      } else {
        enqueueSnackbar(data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
  }
}
