import { JsendResponse, jsendResponseDecoder } from "Utils/jsend";
import * as Yup from "yup";
import { clientIdValidation, requiredValidation } from "Utils/validations";
import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";
import { object, boolean, number, string, nullable } from "decoders";

export interface BasicDialogProps {
  open: boolean;
  onClose: () => void;
}

export type LoginStatus = "login" | "secret-questions";

export interface LoginData {
  status: LoginStatus;
  secretQuestions?: SecretQuestionData[];
  uid?: string;
  token?: string;
}

export interface LoginFormData {
  clientId: string;
  password: string;
}

export interface LoginResponseSuccess {
  uid: string;
  token: string;
}

export interface GetTokenData {
  access: string;
  refresh: string;
  user: UserData;
}

export interface UserData {
  pk: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  full_name: string;
  is_active: boolean;
  last_login: string | null;
  role: string;
  role_id: number | null;
}

export const loginDataKeys: ["clientId", "password"] = ["clientId", "password"];

export interface BaseResponseFail {
  errorKey: string;
  message: string;
}

export type LoginResponse = JsendResponse<LoginResponseSuccess, BaseResponseFail>;

export type GetTokenResponse = JsendResponse<GetTokenData, BaseResponseFail>;

export type GetCurrentUserResponse = JsendResponse<UserData, string>;

const userDecoderKey = object({
  pk: number,
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  full_name: string,
  is_active: boolean,
  last_login: nullable(string),
  role: string,
  role_id: nullable(number),
});

export const getTokenResponseDecoder = jsendResponseDecoder(
  object({
    access: string,
    refresh: string,
    user: userDecoderKey,
  }),
  object({
    errorKey: string,
    message: string,
  })
);

export const loginResponseDecoder = jsendResponseDecoder(
  object({
    uid: string,
    token: string,
  }),
  object({
    errorKey: string,
    message: string,
  })
);

export const getCurrentUserResponseDecoder = jsendResponseDecoder(userDecoderKey, string);

export const loginDataSchema = Yup.object({
  [loginDataKeys[0]]: clientIdValidation,
  [loginDataKeys[1]]: requiredValidation,
});
