import { Stack } from "@mui/material";
import SideInfo from "../SideInfo/SideInfo";

export default function AccessInfo(): JSX.Element {
  const steps = [
    "Nacionalidad dominicana.",
    "No ser una persona políticamente expuesta (PEP).",
    "Tener tu código empleador en caso de que tu empresa está afiliada.",
    "Tener un correo electrónico válido.",
    "Acceso a un dispositivo con cámara.",
    "Poseer contigo tu documento de identificación o cedula.",
  ];
  return (
    <Stack spacing={2} direction="column">
      <SideInfo
        title={"Proceso de afiliación digital"}
        description={"Para poder solicitar tu cuenta de ahorro digital deberás cumplir los siguientes requisitos:"}
        steps={steps}
      />
    </Stack>
  );
}
