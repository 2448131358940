import { useIsSmall } from "Hooks/useIsSmall";
import { ProductTypeSummary } from "Pages/Products/ProductTypeSummary";
import { Loading } from "Shared/Loading/Loading";
import { useCallback, useState } from "react";
import { useAsync } from "Hooks/useAsync";
import { GeneralBalanceData } from "Services/api/finance/interfaces";
import { getGeneralBalance } from "Services/api/finance/finance";
import { useSnackbar } from "notistack";
import { SavingAccountSummary } from "Pages/Products/SavingAccountSummary";
import { LoanSummary } from "Pages/Products/LoanSummary";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { CertificateSummary } from "Pages/Products/CertificateSummary";
import Contact from "Shared/Contact/Contact";
import { Stack, Card } from "@mui/material";

const initialValues: GeneralBalanceData = {
  savingAccount: {
    total: "0.00",
    items: [],
  },
  certificate: {
    total: "0.00",
    items: [],
  },
  loan: {
    total: "0.00",
    items: [],
  },
};

export function Products(): JSX.Element {
  const isSmall = useIsSmall();
  const { enqueueSnackbar } = useSnackbar();

  const [generalBalance, setGeneralBalance] = useState<GeneralBalanceData>(initialValues);
  const getInitData = useCallback(async () => {
    const { status, data } = await getGeneralBalance();
    if (status === "success") {
      setGeneralBalance(data);
    }
  }, []);
  const { status } = useAsync(getInitData, null, (error) => {
    enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    console.error(error);
  });

  return (
    <Stack direction={isSmall ? "column" : "row"} spacing={5}>
      <Stack flexBasis={"70%"} width="100%" spacing={1}>
        {status === "pending" || status === "idle" ? (
          <Loading />
        ) : status === "success" ? (
          <>
            <ProductTypeSummary data={generalBalance} />
            <Stack
              spacing={2}
              component={Card}
              sx={{ padding: "15px", backgroundColor: "#F6F9F2 !important" }}
              elevation={0}
            >
              <SavingAccountSummary data={generalBalance.savingAccount} />
              <CertificateSummary data={generalBalance.certificate} />
              <LoanSummary data={generalBalance.loan} />
            </Stack>
          </>
        ) : status === "error" ? (
          <div>{GENERIC_ERROR_MESSAGE}</div>
        ) : (
          <></>
        )}
      </Stack>
      <Stack flexBasis={"30%"} width="100%">
        <Contact />
      </Stack>
    </Stack>
  );
}
