import { Stack, AppBar, Toolbar, Box, ButtonBase } from "@mui/material";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../../../public/logo.png";

export default function NavBar({
  children,
  rightMenu,
}: PropsWithChildren<{ rightMenu?: React.ReactNode }>): JSX.Element {
  return (
    <Stack sx={{ height: "100vh", justifyContent: "space-between" }}>
      <AppBar sx={{ boxShadow: "none" }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, pl: "24px" }}>
            <ButtonBase component={RouterLink} to="/">
              <Box src={logo} sx={{ height: 40 }} component="img" alt="Cooperativa Barcelona logo" />
            </ButtonBase>
          </Box>
          <Box sx={{ flexGrow: 0, pr: "24px" }}>{rightMenu}</Box>
        </Toolbar>
      </AppBar>

      <main>{children}</main>
    </Stack>
  );
}
