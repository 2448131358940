import { createTheme, ThemeProvider, CssBaseline, IconButton } from "@mui/material";
import { esES } from "@mui/material/locale";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Registration from "../pages/Registration/Registration";
import baselineOverrides from "./baselineOverrides.css";
import Login from "Pages/Login/Login";
import { NotFound } from "Pages/NotFound/NotFound";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { createRef } from "react";
import ForgotPassword from "Pages/ForgotPassword/ForgotPassword";
import Dashboard from "Layout/Dashboard/Dashboard";
import { Products } from "Pages/Products/Products";
import { SavingDetails } from "Pages/SavingDetails/SavingDetails";
import { CertificateDetails } from "Pages/CertificateDetails/CertificateDetails";
import { LoanDetails } from "Pages/LoanDetails/LoanDetails";
import Associate from "Pages/Associate/Associate";
import { Clear } from "@mui/icons-material";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#315500",
        light: "#547725",
      },
    },
    typography: {
      fontFamily: ['"Helvetica Neue"', "Roboto", "Arial", "sans-serif"].join(","),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: baselineOverrides,
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            fontWeight: 700,
          },
        },
      },
    },
  },
  esES
);

export function App() {
  const notistackRef = createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    (notistackRef.current as SnackbarProvider).closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        hideIconVariant
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)} aria-label="remover alerta" component="span">
            <Clear />
          </IconButton>
        )}
      >
        <Router>
          <Routes>
            <Route path="/" element={<LoggedIn Element={Products} />} />
            <Route path="/productos" element={<LoggedIn Element={Products} />} />
            <Route path="/ahorro/:number" element={<LoggedIn Element={SavingDetails} />} />
            <Route path="/certificado/:number" element={<LoggedIn Element={CertificateDetails} />} />
            <Route path="/prestamo/:number" element={<LoggedIn Element={LoanDetails} />} />
            <Route path="/socio" element={<LoggedIn Element={Associate} />} />
            <Route path="/acceso" element={<Login />} />
            <Route path="/restaurar-acceso/:uid/:token" element={<ForgotPassword />} />
            <Route path="/registro" element={<Registration />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

function LoggedIn({ Element }: { Element: () => JSX.Element }) {
  return (
    <Dashboard>
      <Element />
    </Dashboard>
  );
}
