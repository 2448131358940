import { Clear, Edit } from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { useAsync } from "Hooks/useAsync";
import { useHandleError } from "Hooks/useHandleError";
import { Dispatch, Fragment, useCallback, useState } from "react";
import { Profile } from "Services/api/profile/interfaces";
import { getProfile } from "Services/api/profile/profile";
import { Loading } from "Shared/Loading/Loading";
import { SectionTitle } from "Shared/styled";
import ValueElement from "Shared/ValueElement/ValueElement";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { AddressChange } from "./AddressChange";
import { EmailChange } from "./EmailChange";
import { EditContentProps } from "./interfaces";
import { PasswordChange } from "./PasswordChange";
import { PhoneChange } from "./PhoneChange";
import { QuestionsChange } from "./QuestionsChange";

const initValues: Profile = {
  id: "",
  phone: "",
  email: "",
  address: {
    country: "",
    state: "",
    city: "",
    region: "",
    reference: "",
    sector: "",
  },
  securityQuestions: [],
};

export default function Associate() {
  const handleError = useHandleError();

  const [profile, setProfile] = useState<Profile>(initValues);

  const getInitData = useCallback(async () => {
    const { data } = await getProfile();
    setProfile(data);
  }, []);
  const { status } = useAsync(getInitData, null, handleError);

  return (
    <Fragment>
      {status === "pending" || status === "idle" ? (
        <Loading />
      ) : status === "success" ? (
        <AssociateInfo profile={profile} setProfile={setProfile} />
      ) : status === "error" ? (
        <div>{GENERIC_ERROR_MESSAGE}</div>
      ) : (
        <></>
      )}
    </Fragment>
  );
}

type View = "" | "password" | "email" | "phone" | "address" | "questions";

function AssociateInfo({ profile, setProfile }: { profile: Profile; setProfile: Dispatch<Profile> }) {
  const [view, setView] = useState<View>("");

  return (
    <Fragment>
      <ProfileInfo profile={profile} setView={setView} />
      <EditDialog profile={profile} setProfile={setProfile} view={view} onClose={() => setView("")}></EditDialog>
    </Fragment>
  );
}

function ProfileInfo({ profile, setView }: { profile: Profile; setView: Dispatch<View> }) {
  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <Stack
          spacing={2}
          component={Card}
          style={{ padding: "30px", backgroundColor: "#F6F6F6", height: "100%" }}
          elevation={0}
        >
          <SectionTitle variant="h1">Datos del socio</SectionTitle>

          <ValueField title="Documento de identidad" value={profile.id} />

          <EditField
            title="Correo electronico"
            value={profile.email}
            onClick={() => setView("email")}
            testId="edit-email"
          />

          <EditField
            title="Telefono movil"
            value={profile.phone}
            onClick={() => setView("phone")}
            testId="edit-phone"
          />

          <EditField
            title="Dirección"
            value={`${profile.address.reference}, ${profile.address.sector}, ${profile.address.city}, ${profile.address.country}`}
            onClick={() => setView("address")}
            testId="edit-address"
          />
        </Stack>
      </Grid>
      <Grid item sm={6}>
        <Stack
          spacing={2}
          component={Card}
          style={{ padding: "30px", backgroundColor: "#F6F6F6", height: "100%" }}
          elevation={0}
        >
          <SectionTitle variant="h1">Datos de acceso y seguridad</SectionTitle>

          <EditField title="Contraseña" value="••••" onClick={() => setView("password")} testId="edit-password" />

          <EditField
            title="Preguntas de seguridad"
            value={
              <List sx={{ padding: 0 }}>
                {profile.securityQuestions.map(({ question }, index) => (
                  <ListItem key={question} sx={{ padding: 0 }}>
                    <ListItemText>
                      {index + 1}. {question}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            }
            onClick={() => setView("questions")}
            testId="edit-questions"
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

function ValueField({ title, value }: { title: React.ReactNode; value: React.ReactNode }) {
  return <ValueElement title={title} value={value} stackProps={{ spacing: 2, pr: "15px" }} />;
}

function EditField({
  title,
  value,
  onClick,
  testId,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
  onClick: () => void;
  testId: string;
}) {
  return (
    <ButtonBase sx={{ textAlign: "left", justifyContent: "flex-start" }} onClick={onClick} data-testid={testId}>
      <ValueField
        title={
          <Stack direction={"row"} alignItems="center" gap="5px">
            {title}
            <Edit fontSize="small" />
          </Stack>
        }
        value={value}
      />
    </ButtonBase>
  );
}

export interface Props {
  view: View;
  onClose: () => void;
  profile: Profile;
  setProfile: Dispatch<Profile>;
}

type editTuple = [string, (props: EditContentProps) => JSX.Element];
const editContentMap: {
  password: editTuple;
  email: editTuple;
  phone: editTuple;
  address: editTuple;
  questions: editTuple;
} = {
  password: ["Cambiar contraseña", PasswordChange],
  email: ["Cambiar correo electronico", EmailChange],
  phone: ["Cambiar telefono movil", PhoneChange],
  address: ["Cambiar dirección", AddressChange],
  questions: ["Cambiar preguntas de seguridad", QuestionsChange],
};

function EditDialog(props: Props) {
  const { profile, setProfile, view, onClose } = props;
  if (!view) return null;
  const [title, EditContent] = editContentMap[view];
  return (
    <Dialog onClose={onClose} open fullWidth maxWidth="md">
      <Stack component={DialogTitle} direction={"row"} justifyContent="space-between" alignItems={"center"}>
        {title}
        <IconButton onClick={onClose} sx={{ color: "#000" }}>
          <Clear />
        </IconButton>
      </Stack>
      <DialogContent>
        <EditContent closeDialog={onClose} profile={profile} setProfile={setProfile} />
        <Button onClick={onClose} sx={{ mt: "25px" }} fullWidth>
          Cancelar
        </Button>
      </DialogContent>
    </Dialog>
  );
}
